import { BottomNavigation, BottomNavigationAction } from '@mui/material'
import { ReactElement, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import HistoryIcon from '@mui/icons-material/History'
import SettingsIcon from '@mui/icons-material/Settings'
import PlaylistAddCircleIcon from '@mui/icons-material/PlaylistAddCircle'

function Footer(): ReactElement {
  const navigate = useNavigate()
  const [value, setValue] = useState(0)

  useEffect(() => {
    if (window.location.href.includes('events')) setValue(0)
    if (window.location.href.includes('history')) setValue(1)
    if (window.location.href.includes('settings')) setValue(2)
  }, [])

  return (
    <BottomNavigation
      showLabels
      value={value}
      className={'fixed bottom-0 inset-x-0 z-10'}
      onChange={(event, newValue) => {
        setValue(newValue)
        if (newValue === 0) navigate('/events')
        if (newValue === 1) navigate('/history')
        if (newValue === 2) navigate('/settings')
      }}
    >
      <BottomNavigationAction label="Events" icon={<PlaylistAddCircleIcon />} />
      <BottomNavigationAction label="History" icon={<HistoryIcon />} />
      <BottomNavigationAction label="Settings" icon={<SettingsIcon />} />
    </BottomNavigation>
  )
}

export default Footer

import { ReactElement } from 'react'
import { useUser } from '../hooks/user.hooks'
import { PaymentMethod } from '@stripe/stripe-js'
import CardIcon from './CardIcon'
import styled from 'styled-components'
import tw from 'twin.macro'

type CardType = 'visa' | 'mastercard' | 'amex'

function CreditCard({
  info,
  selected,
  onClick,
  size = 'md'
}: {
  info: PaymentMethod
  selected: boolean
  onClick: () => void
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl'
}): ReactElement {
  const CreditCardContainer = styled.div`
    ${tw`w-96 h-56 m-auto bg-indigo-100 rounded-xl relative text-white shadow-2xl transition-all transform duration-100 ease-in-out`}
    ${selected &&
    tw`scale-110 shadow-xl shadow-indigo-700/30 ring ring-indigo-500 ring-offset-4`}
    ${size === 'xs' && tw`scale-[75%]`}
    ${size === 'sm' && tw`scale-[88%]`}
    ${size === 'lg' && tw`scale-[113%]`}
    ${size === 'xl' && tw`scale-[125%]`}
  `
  return (
    <CreditCardContainer id="creditcard" onClick={onClick}>
      <img
        className="relative object-cover w-full h-full rounded-xl"
        src="https://i.imgur.com/kGkSg1v.png"
      />
      <div className="w-full px-5 absolute top-4">
        <div className="flex justify-between items-center">
          <div className="pt-1 ">
            <p className="font-light lg:text-lg">Card Number</p>
            <p className="font-medium tracking-more-wider text-sm lg:text-lg">
              xxxx xxxx {size === 'xs' || size === 'sm' ? '' : 'xxxx'}{' '}
              {info.card?.last4}
            </p>
          </div>
          <div className="w-16 h-16">
            <CardIcon name={info.card?.brand as string} />
          </div>
        </div>
        <div className="pt-4 pr-6">
          <div className="flex justify-between">
            <div className="flex flex-row justify-center items-center space-x-1">
              <p className="font-light text-xs">Expiry</p>
              <p className="font-medium tracking-wider text-sm">
                {info.card?.exp_month}/{info.card?.exp_year}
              </p>
            </div>

            <div className="flex flex-row justify-center items-center space-x-1">
              <p className="font-light text-xs">CVV</p>
              <p className="font-bold tracking-more-wider text-sm">···</p>
            </div>
          </div>
        </div>
      </div>
    </CreditCardContainer>
  )
}

export default CreditCard

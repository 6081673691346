import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import { ReactElement, useState, useEffect } from 'react'
import TaskAltIcon from '@mui/icons-material/TaskAlt'
import EditIcon from '@mui/icons-material/Edit'
import InstagramIcon from '@mui/icons-material/Instagram'
import InputAdornment from '@mui/material/InputAdornment'
import CircularProgress from '@mui/material/CircularProgress'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSoundcloud } from '@fortawesome/free-brands-svg-icons'
import Link from '@mui/material/Link'
import LaunchIcon from '@mui/icons-material/Launch'

type SocialType = 'instagram' | 'soundCloud'
type FormMethod = 'add' | 'update'

const CircularIndeterminate = () => {
  return (
    <div className="w-full h-full flex flex-grow items-start justify-center p-6">
      <CircularProgress size={'6rem'} />
    </div>
  )
}

const Instagram = ({
  input,
  reviseSearch,
  onConfirm
}: {
  input: string
  reviseSearch: (str: string) => void
  onConfirm: (str: string) => void
}) => {
  const trimmed = input.trim()
  const [isLoading, setLoading] = useState<boolean>(true)
  const url = `https://www.instagram.com/${trimmed}/embed`

  useEffect(() => {
    setTimeout(() => {
      setLoading(false)
    }, 1000)
  }, [])

  return (
    <section className="text-gray-600 body-font relative">
      {isLoading ? (
        <CircularIndeterminate />
      ) : (
        <div className="container mx-auto flex sm:flex-nowrap flex-wrap">
          <div className="w-full rounded-lg overflow-hidden flex items-end justify-start relative">
            <iframe
              width="100%"
              height="320px"
              className="relative block"
              src={url}
            ></iframe>
          </div>
          <div className="flex flex-col w-full">
            <p className="w-full text-center text-xs text-gray-500 my-3">
              <span className="text-base font-semibold">
                Is this your account?
              </span>{' '}
              <br /> Please confirm or update your search.
              <br /> (Note: If your account is private it{' '}
              <b>will not display</b>)
            </p>
            <div className="w-full flex justify-between">
              <Button
                component="label"
                onClick={() => reviseSearch(trimmed)}
                color="warning"
                variant="contained"
                startIcon={<EditIcon />}
              >
                Edit Search
              </Button>
              <Button
                component="label"
                variant="contained"
                onClick={() => onConfirm(trimmed)}
                startIcon={<TaskAltIcon />}
              >
                Confirm
              </Button>
            </div>
          </div>
        </div>
      )}
    </section>
  )
}

const SoundCloud = ({
  input,
  reviseSearch,
  onConfirm
}: {
  input: string
  reviseSearch: (str: string) => void
  onConfirm: (str: string) => void
}) => {
  const trimmed = input.trim()
  const [isLoading, setLoading] = useState<boolean>(true)
  const [linkVisited, setLinkVisited] = useState<boolean>(false)
  const url = `https://soundcloud.com/${trimmed}`

  useEffect(() => {
    setTimeout(() => {
      setLoading(false)
    }, 1000)
  }, [])

  return (
    <section className="text-gray-600 body-font relative">
      {isLoading ? (
        <CircularIndeterminate />
      ) : (
        <div className="container mx-auto flex sm:flex-nowrap flex-wrap">
          <div className="w-full rounded-lg overflow-hidden flex items-end justify-start relative">
            <Link
              color={!linkVisited ? 'primary' : 'secondary'}
              className="w-full flex items-center justify-center text-lg"
              target="_blank"
              onClick={() => setLinkVisited(true)}
              href={url}
              underline="always"
            >
              <LaunchIcon />{' '}
              <span className="ml-1 text-lg">{`soundcloud.com/${trimmed}`}</span>
            </Link>
          </div>
          <div className="flex flex-col w-full">
            <p className="w-full text-center text-xs text-gray-500 my-3">
              <span className="text-base font-semibold">
                Is this your account?
              </span>{' '}
            </p>
            <p className="w-full text-start text-sm text-gray-500 mb-3">
              <b> Please visit link</b> to confirm if the username is correct.
            </p>
            <div className="w-full flex justify-between">
              <Button
                disabled={!linkVisited}
                component="label"
                onClick={() => reviseSearch(trimmed)}
                color="warning"
                variant="contained"
                startIcon={<EditIcon />}
              >
                Edit Search
              </Button>
              <Button
                disabled={!linkVisited}
                component="label"
                onClick={() => onConfirm(trimmed)}
                variant="contained"
                startIcon={<TaskAltIcon />}
              >
                Confirm
              </Button>
            </div>
          </div>
        </div>
      )}
    </section>
  )
}

function SocialForm({
  value,
  account,
  handleConfirm,
  editable
}: {
  value: string
  account: SocialType
  handleConfirm: (prop: string, value: string) => void
  editable: boolean
}): ReactElement {
  const [viewIframe, setIframeView] = useState<boolean>(false)
  const [userName, setUserName] = useState<string>(value)
  const [confirmed, setConfirmed] = useState<boolean>(false)

  useEffect(() => {
    setUserName(value)
  }, [value])

  const resetForm = (query: string) => {
    setUserName(query)
    setIframeView(false)
    setConfirmed(false)
  }
  const confirm = (value: string) => {
    setUserName(value)
    setConfirmed(true)
    setIframeView(false)
  }

  const confirmInstagram = (str: string) => {
    confirm(str)
    handleConfirm('instagram', str)
  }

  const confirmSoundCloud = (str: string) => {
    confirm(str)
    handleConfirm('soundCloud', str)
  }

  return (
    <section className="text-gray-600 body-font">
      <div className="container mx-auto flex flex-wrap items-center">
        <div className="w-full bg-gray-200 bg-opacity-50 rounded-lg p-4 flex flex-col">
          <h2 className="text-gray-900 text-lg capitalize font-medium title-font mb-5">
            {account}
          </h2>
          <div className="relative mb-4">
            <TextField
              id="outlined-controlled"
              disabled={viewIframe || confirmed}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <>
                      {account === 'instagram' && <InstagramIcon />}
                      {account === 'soundCloud' && (
                        <FontAwesomeIcon icon={faSoundcloud} size="xl" />
                      )}
                    </>
                  </InputAdornment>
                )
              }}
              label="Username"
              value={userName}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setUserName(event.target.value)
              }}
            />
          </div>
          {editable && (
            <>
              {!viewIframe && !confirmed && (
                <Button
                  disabled={userName === ''}
                  onClick={() => setIframeView(true)}
                  variant="outlined"
                >
                  Search
                </Button>
              )}
              {viewIframe && !confirmed && (
                <>
                  {account === 'instagram' && (
                    <Instagram
                      onConfirm={confirmInstagram}
                      reviseSearch={resetForm}
                      input={userName}
                    />
                  )}
                  {account === 'soundCloud' && (
                    <SoundCloud
                      onConfirm={confirmSoundCloud}
                      reviseSearch={resetForm}
                      input={userName}
                    />
                  )}
                </>
              )}
            </>
          )}
        </div>
      </div>
    </section>
  )
}

export default SocialForm

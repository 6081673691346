import { AmEx } from './AmEx'
import { Diners } from './Diners'
import { Discover } from './Discover'
import { HeyDJ } from './HeyDJ'
import { JCB } from './JCB'
import { MasterCard } from './MasterCard'
import { Stripe } from './Stripe'
import { UnionPay } from './UnionPay'
import { Visa } from './Visa'

const Icons = {
  AmEx,
  Diners,
  Discover,
  HeyDJ,
  JCB,
  MasterCard,
  Stripe,
  UnionPay,
  Visa
}

export default Icons

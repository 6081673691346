/* eslint-disable @typescript-eslint/no-empty-function */
import * as React from 'react'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { InputAdornment } from '@mui/material'
import { useAbortableFetch } from '../hooks/abortableFetch.hooks'
import { useSnackbar } from 'notistack'

const noon = () => {}

export default function UpdateEventMinimumDialog({
  onFailure = noon,
  onSuccess = noon,
  minimumTip,
  eventID
}: {
  minimumTip: number
  eventID: string
  onSuccess?: () => void
  onFailure?: () => void
}) {
  const [open, setOpen] = React.useState(false)
  const [tip, setTip] = React.useState(minimumTip)

  const { enqueueSnackbar } = useSnackbar()

  const openSnackbar = (key: string, message: string) =>
    enqueueSnackbar({
      key,
      anchorOrigin: {
        horizontal: 'left',
        vertical: 'bottom'
      },
      TransitionProps: {
        direction: 'up'
      },
      message,
      preventDuplicate: true,
      persist: false,
      autoHideDuration: 2_500
    })

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const { triggerFn: submitSongFn } = useAbortableFetch({
    type: 'POST',
    url: '/api/event/minimum',
    authed: true,
    body: {
      minimum: tip,
      event: eventID
    },
    requestSuccessful() {
      onSuccess()
      openSnackbar('event.updated', 'Event Tip Successfully Updated')
      handleClose()
    },
    requestFailed() {
      onFailure()
      openSnackbar('event.updated', 'Event Tip Failed To Update')
      handleClose()
    }
  })

  return (
    <div>
      <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Update Tip
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Update Event Tip</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Update the event tip in real-time, just enter the new tip below
            click accept
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="tip"
            label="DJ Tip (optional)"
            fullWidth
            variant="standard"
            value={tip}
            onChange={(_event: React.ChangeEvent<HTMLInputElement>) => {
              const value = Number(_event.target.value)
              if (!Number.isNaN(value)) setTip(value)
            }}
            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              )
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={() => submitSongFn()}>Accept</Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

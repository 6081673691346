import { ReactElement } from 'react'
import QRCode from 'react-qr-code'
import { styled } from 'styled-components'

function QRCodeDialog(props: { value?: string; size?: number }): ReactElement {
  const value = props?.value || 'test'
  const size = props?.size || 256
  const QRCodeContainer = styled.div`
    height: auto;
    margin: 0 auto;
    max-width: ${size}px;
    width: 100%;
  `
  return (
    <QRCodeContainer>
      <QRCode
        size={size}
        style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
        value={value}
        viewBox={`0 0 ${size} ${size}`}
      />
    </QRCodeContainer>
  )
}

export default QRCodeDialog

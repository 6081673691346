import styled from '@emotion/styled'
import { ReactElement } from 'react'
import tw from 'twin.macro'

const MainContainer = styled.main`
  ${tw`shrink-0 flex flex-col flex-grow justify-start items-center overflow-hidden`}
`

function Main(props: React.PropsWithChildren): ReactElement {
  return (
    <MainContainer id="content" role="main">
      {props.children}
    </MainContainer>
  )
}

export default Main

import * as React from 'react'
import Box from '@mui/material/Box'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { useEffect, useState } from 'react'
import StatusBadge from '../../components/StatusBadge'
import TableRowsLoader from '../../components/Table/TableRowsLoader'
import EnhancedTableHead from '../../components/Table/EnhancedTableHead'
import EnhancedTableToolbar from '../../components/Table/EnhancedTableToolbar'
import { RequestStatus, RequestData } from '../../../shared/interfaces/Request'
import { getComparator } from '../../utils/table.utils'
import { dateToMS, formatDate } from '../../utils/date.utils'

type Order = 'asc' | 'desc'

interface Data {
  name: string
  tip: number
  eventName: string
  eventDate: number
  requestStatus: RequestStatus
  requestId?: string
}

const formatRowData = (data: RequestData[]): Data[] => {
  const rows: any[] = []
  data.map((row) => {
    const rowData: Data = {
      name: row.song.name,
      tip: row.tip,
      eventName: row.event.name,
      eventDate: dateToMS(row.createdAt),
      requestStatus: row.status,
      requestId: row.id
    }
    rows.push(rowData as Data)
  })
  return rows as Data[]
}

interface HeadCell {
  disablePadding: boolean
  id: keyof Data
  label: string
  numeric: boolean
}

const headCells: readonly HeadCell[] = [
  {
    id: 'tip',
    numeric: true,
    disablePadding: false,
    label: 'Tip'
  },
  {
    id: 'eventDate',
    numeric: true,
    disablePadding: false,
    label: 'Date'
  },
  {
    id: 'requestStatus',
    numeric: false,
    disablePadding: false,
    label: 'Status'
  },
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'Song Name'
  },
  {
    id: 'eventName',
    numeric: false,
    disablePadding: false,
    label: 'Event Name'
  }
]

export default function HistoryPage({
  rowData,
  isLoading
}: {
  rowData: Data[] | any[]
  isLoading: boolean
}) {
  const [order, setOrder] = React.useState<Order>('asc')
  const [orderBy, setOrderBy] = React.useState<keyof Data>('tip')
  const [selected, setSelected] = React.useState<readonly string[]>([])
  const [page, setPage] = React.useState(0)
  const [dense, setDense] = React.useState(true)
  const [rowsPerPage, setRowsPerPage] = React.useState(5)
  const [rows, updateRows] = useState<Data[] | any[]>([])

  useEffect(() => {
    updateRows(rowData)
    if (rowData.length > 0) {
      setOrder(order === 'asc' ? 'desc' : 'asc')
      setOrderBy('tip')
    }
  }, [rowData])

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleChangeDense = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDense(event.target.checked)
  }

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0

  const visibleRows = React.useMemo(
    () =>
      rows
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        .sort(getComparator(order, orderBy)),
    [order, orderBy, page, rowsPerPage]
  )

  return (
    <Box
      sx={{
        width: '100%',
        backgroundColor: 'white',
        height: '100%',
        overflowy: 'hidden'
      }}
    >
      <Paper sx={{ width: '100%' }}>
        <EnhancedTableToolbar numSelected={selected.length} />
        <TableContainer
          sx={{
            background: 'rgb(15 23 42 / var(--tw-bg-opacity))'
          }}
        >
          <Table
            sx={{ minWidth: 750 }}
            stickyHeader
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
          >
            <EnhancedTableHead
              headCells={headCells}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody
              style={{
                height: '100%',
                background: 'white'
              }}
            >
              {isLoading ? (
                <TableRowsLoader
                  rowsNum={rowsPerPage}
                  colsNum={headCells.length}
                />
              ) : (
                visibleRows.map((row, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`

                  return (
                    <TableRow
                      hover
                      tabIndex={-1}
                      key={row.requestId}
                      sx={{ cursor: 'pointer' }}
                    >
                      <TableCell align="right">${row.tip}.00</TableCell>
                      <TableCell align="right">
                        {formatDate(row.eventDate)}
                      </TableCell>
                      <TableCell align="center">
                        <StatusBadge status={row.requestStatus} />
                      </TableCell>
                      <TableCell component="th" id={labelId} scope="row">
                        {row.name}
                      </TableCell>
                      <TableCell align="left">{row.eventName}</TableCell>
                    </TableRow>
                  )
                })
              )}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (dense ? 33 : 53) * emptyRows
                  }}
                >
                  <TableCell colSpan={headCells.length} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      {/* <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Bottom Nav"
      /> */}
    </Box>
  )
}

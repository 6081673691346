import { ReactElement, useEffect, useState, useRef } from 'react'
import { useAbortableFetch } from '../hooks/abortableFetch.hooks'
import RequestQueue from './RequestQueue'
import SongCard from './SongCard'
import tw from 'twin.macro'
import InfiniteScroll from 'react-infinite-scroll-component'

interface SpotifySearchResponse {
  song: {
    name: string
    artists: string[]
    // Required by Spotify
    spotifyURL: string
  }
  album: {
    name: string
    albumArt: {
      height: number
      width: number
      url: string
    }
  }
}

function SongSearch({
  reset,
  requests,
  onSongSelect
}: {
  reset: boolean
  requests: any[]
  onSongSelect: (searchResponse: SpotifySearchResponse) => void
}): ReactElement {
  const [search, updateSearch] = useState<string>('')
  const [response, setResponse] = useState<SpotifySearchResponse[]>([])
  const [page, setPage] = useState<number>(1)
  const [total, setTotal] = useState<number | null>(null)
  const [hasMore, setHasMore] = useState<boolean>(true)

  const { isLoading, triggerFn: loadSearchResults } = useAbortableFetch<any>({
    type: 'GET',
    url: `/api/event/songSearch?page=${page}&q=${encodeURI(search)}`,
    authed: true,
    requestFailed({ error }) {
      console.error(error)
    },
    requestSuccessful({ data }) {
      if (!data.message) {
        concatResponse(data.items)
        setTotal(data.total)
      }
    }
  })

  const concatResponse = (arr: any[]) => {
    setResponse(response.concat(arr))
  }

  const incrementPage = () => {
    setPage(page + 1)
  }

  const loadScroller = () => {
    if (response.length === total) {
      setHasMore(false)
      return
    }
    incrementPage()
    loadSearchResults({
      url: `/api/event/songSearch?page=${page + 1}&q=${encodeURI(search)}`
    })
  }

  const searchHandler = (value: string) => {
    onSearchChanges(false)
    updateSearch(value)
  }

  const onSearchChanges = (reset: boolean) => {
    if (reset) updateSearch('')
    setResponse([])
    setPage(1)
    setTotal(null)
    setHasMore(true)
  }

  const loadRequest = () => {
    if (search !== '') {
      loadSearchResults()
    } else setResponse([])
  }
  useEffect(loadRequest, [search])

  useEffect(() => {
    if (reset) {
      onSearchChanges(true)
    }
  }, [reset])

  const SearchResponse = ({ resp = [] }: { resp: SpotifySearchResponse[] }) => {
    if (resp && resp > []) {
      return (
        <>
          {resp.map((searchResponse, i) => {
            return (
              <div
                className="p-2 w-full"
                key={searchResponse.song.spotifyURL}
                onClick={() => onSongSelect(searchResponse)}
              >
                <SongCard view={'search'} data={searchResponse} />
              </div>
            )
          })}
        </>
      )
    } else {
      return <></>
    }
  }

  const NoResponseFound = () => (
    <div className="flex flex-col flex-wrap flex-grow">
      <div className="text-center flex flex-col justify-center flex-grow h-full w-full">
        <h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-white">
          Start typing to search a song to request to the DJ.
        </h1>
        <p className="text-gray-400 mb-8 leading-relaxed">
          To review the status of any previously made requests, check out the
          history page.
        </p>
      </div>
    </div>
  )

  return (
    <div className="flex flex-col flex-grow w-full h-full px-2 mt-2 overflow-y-scroll">
      <div className="flex rounded-md shadow-sm w-full">
        <input
          type="text"
          placeholder="Search Songs"
          className="py-2 px-3 pr-11 block w-full border-gray-200 shadow-sm rounded-md text-xl focus:z-10 focus:border-blue-500 focus:ring-blue-500 dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400"
          value={search}
          onChange={(e) => searchHandler(e.target.value)}
        />
      </div>
      <div className="flex flex-col flex-grow justify-center md:flex-row md:flex-wrap w-full pb-4">
        {response && response.length > 0 ? (
          <InfiniteScroll
            dataLength={response.length}
            next={loadScroller}
            hasMore={hasMore}
            loader={
              <>
                <h4>Loading...</h4>
              </>
            }
          >
            {<SearchResponse resp={response} />}
          </InfiniteScroll>
        ) : (
          // <SearchResponse resp={response} />
          <>
            {requests && requests.length === 0 ? (
              <NoResponseFound />
            ) : (
              <RequestQueue requests={requests} />
            )}
          </>
        )}
      </div>
    </div>
  )
}

export default SongSearch

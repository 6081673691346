import { ReactElement, useEffect, useState } from 'react'
import {
  Button,
  DialogActions,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  InputAdornment,
  Checkbox,
  FormControlLabel,
  Autocomplete
} from '@mui/material'
import * as React from 'react'
import Box from '@mui/material/Box'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

import { DemoItem } from '@mui/x-date-pickers/internals/demo'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker'
import { NewEvent } from '../../../shared/interfaces/Event'
import { useAbortableFetch } from '../../hooks/abortableFetch.hooks'

function CreateEventDialog({
  isOpen,
  dialogClose,
  form,
  onInputChange,
  submitForm,
  required
}: {
  isOpen: boolean
  dialogClose: () => void
  form: NewEvent
  onInputChange: (data: any) => void
  submitForm: (data: any) => void
  required: boolean
}): ReactElement {
  const [formValid, setFormValid] = useState(false)

  const [addressQuery, setAddressQuery] = useState<string>('')
  const [addressOptions, setAddressOptions] = useState<any[]>([])

  const { isLoading, triggerFn: loadMapResults } = useAbortableFetch<any>({
    type: 'GET',
    url: `/api/event/addressSearch?q=${encodeURI(addressQuery)}`,
    authed: true,
    requestFailed({ error }) {
      console.error(error)
    },
    requestSuccessful({ data }) {
      setAddressOptions(data.results)
    }
  })

  const loadRequest = () => {
    if (addressQuery !== '') {
      loadMapResults()
    } else {
      console.log('blank')
    }
  }
  useEffect(loadRequest, [addressQuery])

  const handleFreeEvent = (checked: boolean) => {
    if (checked) {
      onInputChange({
        ...form,
        restrictToFree: checked,
        minimumTip: '0'
      })
    } else {
      onInputChange({
        ...form,
        restrictToFree: checked
      })
    }
  }

  const handleAddressChange = (selected: any) => {
    const addressData = {
      address: selected?.address.freeformAddress ?? ('' as string),
      venue: selected?.poi?.name,
      venueWebsite: selected?.poi?.url,
      venuePhone: selected?.poi?.phone
    }
    onInputChange({
      ...form,
      ...addressData
    })
    console.log(addressData, 'ad')
  }

  useEffect(() => {
    setFormValid(
      form.name.length > 0 && !!form.minimumTip && !!form.date && !!form.address
    )
  }, [form.name, form.minimumTip, form.date, form.address])
  return (
    <Dialog open={isOpen} onClose={() => dialogClose()}>
      <DialogTitle>Create New Event {required ? '(Required)' : ''}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          To create a new event, please fill out the form below.
        </DialogContentText>
        <div className="flex flex-col justify-center items-start space-y-6">
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Event Name"
            type="text"
            fullWidth
            variant="standard"
            value={form.name}
            required
            onChange={(_event: React.ChangeEvent<HTMLInputElement>) => {
              onInputChange({
                ...form,
                name: _event.target.value
              })
            }}
          />
          <Autocomplete
            options={addressOptions}
            fullWidth
            onChange={(event: any, newValue: any | null) => {
              handleAddressChange(newValue)
            }}
            getOptionLabel={(option) => option.poi.name}
            inputValue={addressQuery}
            onInputChange={(event, newInputValue) => {
              setAddressQuery(newInputValue)
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                margin="dense"
                id="address"
                label="Location/Address"
                type="text"
                fullWidth
                variant="standard"
                required
                onChange={(_event: React.ChangeEvent<HTMLInputElement>) => {
                  setAddressQuery(_event.target.value)
                }}
              />
            )}
            renderOption={(props, option) => {
              return (
                <li {...props}>
                  <Grid container alignItems="center">
                    <Grid item sx={{ display: 'flex', width: 44 }}>
                      <LocationOnIcon sx={{ color: 'text.secondary' }} />
                    </Grid>
                    <Grid
                      item
                      sx={{
                        width: 'calc(100% - 44px)',
                        wordWrap: 'break-word'
                      }}
                    >
                      <Box
                        key={0}
                        className="font-semibold mr-2"
                        component="span"
                      >
                        {option.poi.name}
                      </Box>
                      <Box className="text-sm" key={1} component="span">
                        {option.address.freeformAddress}
                      </Box>
                      <Typography variant="body2" color="text.secondary">
                        {/* {option.structured_formatting.secondary_text} */}
                      </Typography>
                    </Grid>
                  </Grid>
                </li>
              )
            }}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={form.restrictToFree}
                onChange={(_event: React.ChangeEvent<HTMLInputElement>) => {
                  handleFreeEvent(_event.target.checked)
                }}
              />
            }
            label="Is this a free event?"
          />
          <TextField
            disabled={form.restrictToFree}
            margin="dense"
            id="tip"
            label="Minimum DJ Tip"
            fullWidth
            variant="standard"
            value={form.minimumTip}
            required
            onChange={(_event: React.ChangeEvent<HTMLInputElement>) => {
              const value = Number(_event.target.value)
              if (!Number.isNaN(value)) {
                onInputChange({
                  ...form,
                  minimumTip: _event.target.value
                })
              }
            }}
            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              )
            }}
          />
          <div id="calendar" className="flex w-full">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoItem label="Event Date (optional)">
                <MobileDateTimePicker
                  sx={{ width: '100%' }}
                  value={form.date}
                  onChange={(newValue) => {
                    onInputChange({
                      ...form,
                      date: newValue
                    })
                  }}
                />
              </DemoItem>
            </LocalizationProvider>
          </div>
          {/* <TextField
            margin="dense"
            id="address"
            label="Location/Address"
            type="text"
            fullWidth
            variant="standard"
            value={form.address}
            required
            onChange={(_event: React.ChangeEvent<HTMLInputElement>) => {
              onInputChange({
                ...form,
                address: _event.target.value
              })
            }}
          /> */}
          <FormControlLabel control={<Checkbox />} label="Repeat Weekly" />
        </div>
      </DialogContent>
      <DialogActions>
        {!required && <Button onClick={() => dialogClose()}>Cancel</Button>}
        <Button onClick={() => submitForm(form)} disabled={!formValid}>
          Submit {formValid}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default CreateEventDialog

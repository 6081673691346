import { useEffect } from 'react'
import { User as UserI } from '../../shared/interfaces/User'
import { useAtom } from 'jotai'
import { userAtom } from '../store/user.store'
import { useSnackbar } from 'notistack'
import { useAbortableFetch } from './abortableFetch.hooks'
import * as Sentry from '@sentry/react'

/**
 * Custom React hook for managing user-related operations and state.
 * This hook provides methods to load, update, and delete user data.
 *
 * @returns {Object} - An object containing user-related functions and state.
 * @property {UserI | null} user - The current user object or null if not authenticated.
 * @property {Function} updateUser - A function to update the user information on the server and reload user data.
 * @property {Function} reloadUser - A function to load or reload the user data from the server.
 * @property {Function} deleteUser - A function to delete the user account on the server and reset the user state.
 */
export function useUser(): {
  /**
   * The current user object or null if not authenticated.
   */
  user: UserI | null
  /**
   * A function to update the user information on the server and reload user data.
   *
   * @param {UserI} user - The updated user object.
   */
  updateUser: (user: UserI) => void
  /**
   * A function to load or reload the user data from the server.
   */
  reloadUser: () => void
  /**
   * A function to delete the user account on the server and reset the user state.
   */
  deleteUser: () => void
  /**
   * is loading property for loading states
   */
  isLoading: boolean
} {
  // Use Jotai atom to store and update the user state.
  const [user, updateUserState] = useAtom(userAtom)

  const { enqueueSnackbar } = useSnackbar()

  const openSnackbar = (key: string, message: string) =>
    enqueueSnackbar({
      key,
      anchorOrigin: {
        horizontal: 'left',
        vertical: 'bottom'
      },
      TransitionProps: {
        direction: 'up'
      },
      message,
      preventDuplicate: true,
      persist: false,
      autoHideDuration: 2_500
    })

  // Function to load or reload the user data from the server.
  const { isLoading, triggerFn: loadUser } = useAbortableFetch<UserI>({
    url: '/api/user/me',
    type: 'GET',
    authed: true,
    requestSuccessful({ data }) {
      Sentry.setUser({
        email: data.email,
        id: data.id,
        segment: data.role,
        username: data.userID
      })
      updateUserState(data)
    }
  })

  const { triggerFn: updateUserFn } = useAbortableFetch<UserI>({
    url: '/api/user/me',
    type: 'PUT',
    authed: true,
    requestSuccessful() {
      openSnackbar('User.Updated', 'Updated User')
      loadUser()
    }
  })

  // Function to update the user information on the server and reload user data.
  const { triggerFn: deleteUserFn } = useAbortableFetch<string>({
    url: '/api/user/me',
    type: 'DELETE',
    authed: true,
    responseType: 'TEXT',
    requestSuccessful() {
      openSnackbar('User.Deleted', 'Deleted User')
      // Reset the user state to null after successful deletion.
      updateUserState(null)

      // Redirect the user to the homepage after account deletion.
      setTimeout(() => {
        window.location.replace(window.location.origin)
      }, 1_000)
    }
  })

  // Load user data on component mount or when the user state is empty.
  useEffect(() => {
    if (!user) loadUser()
  }, [])

  // Return the user state and functions for external use.
  return {
    user,
    updateUser: (user: UserI) => {
      updateUserFn({
        body: user
      })
    },
    reloadUser: () => loadUser(),
    deleteUser: () => deleteUserFn(),
    isLoading
  }
}

import { ReactElement } from 'react'
import { useUser } from '../hooks/user.hooks'

function RoleGaurd(
  props: React.PropsWithChildren & {
    allow: ('user' | 'admin' | 'venue' | 'dj')[]
  }
): ReactElement {
  const { user } = useUser()

  if (user && props.allow.includes(user.role)) return <>{props.children}</>
  else return <></>
}

export default RoleGaurd

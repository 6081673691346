import { useAuth } from '@clerk/clerk-react'
import { PaymentElement } from '@stripe/react-stripe-js'
import { ReactElement, useEffect, useState } from 'react'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import ApplePay from './ApplePay'
import CheckoutForm from './Checkout'
import { CircularProgress } from '@mui/material'
import { useAbortableFetch } from '../hooks/abortableFetch.hooks'

export function AddCash({
  djID,
  amount,
  onFormSubmit
}: {
  djID: string
  amount: number
  onFormSubmit: (paymentMethodID: string) => void
}): ReactElement {
  const stripePromise = loadStripe(import.meta.env.REACT_APP_STRIPE)
  // const [loading, setLoading] = useState(true)
  const [options, setOptions] = useState({
    clientSecret: ''
  })

  const { isLoading, triggerFn: loadIntent } = useAbortableFetch<any>({
    type: 'GET',
    url: `/api/billing/setup/intent?total=${amount ? amount : 1000}&dj=${djID}`,
    authed: true,
    requestStarted() {
      console.log('STARTED')
    },
    requestFailed({ error }) {
      console.error(error)
    },
    requestSuccessful({ data }) {
      console.log(data)
      setOptions({
        clientSecret: data.client_secret
      })
    }
  })

  useEffect(() => {
    loadIntent().then((res) => {
      console.log(res)
    })
  }, [])

  return !options.clientSecret && isLoading ? (
    <CircularProgress />
  ) : (
    <Elements stripe={stripePromise} options={options}>
      <div className="flex flex-col h-full w-full justify-start pt-4">
        <CheckoutForm onFormSubmit={onFormSubmit} />
      </div>
    </Elements>
  )
}

export default AddCash

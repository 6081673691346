import { ReactElement } from 'react'

export function HeyDJ(): ReactElement {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width="100%"
      viewBox="0 0 2386 3400"
      enableBackground="new 0 0 2386 3400"
      xmlSpace="preserve"
    >
      <path
        fill="#FFFFFF"
        opacity="0.000000"
        stroke="none"
        d="
M2.000000,1350.000000 
 C2.000000,900.000061 2.000000,451.000153 2.000000,2.000181 
 C797.333069,2.000121 1592.666138,2.000121 2387.999512,2.000060 
 C2387.999512,1135.332886 2387.999512,2268.665771 2387.999756,3401.999023 
 C1592.667236,3401.999512 797.334473,3401.999512 2.000852,3401.999756 
 C2.000000,2718.333252 2.000000,2034.666626 2.000000,1350.000000 
M1557.807251,1941.960449 
 C1726.137573,1851.596069 1855.026733,1721.965088 1944.464111,1553.179688 
 C1967.420532,1509.856567 1986.721436,1464.920288 2003.228516,1417.712158 
 C2012.922485,1415.303345 2022.616333,1412.894409 2032.523315,1412.588013 
 C2032.555908,1535.607788 2032.589966,1658.627441 2032.604614,1781.647217 
 C2032.604736,1783.300171 2032.358521,1784.953247 2030.330933,1786.475464 
 C2013.321777,1785.657349 1996.434204,1786.816162 1979.686646,1789.820312 
 C1916.789307,1801.103271 1862.814819,1829.187012 1819.286743,1876.231201 
 C1801.725708,1895.210938 1788.393921,1916.953491 1780.917603,1942.040283 
 C1762.570801,2003.603760 1801.303589,2050.782959 1855.204712,2062.962891 
 C1879.040527,2069.484131 1903.354980,2069.868896 1927.614624,2067.200928 
 C1966.552002,2062.918213 2002.812744,2049.998047 2036.640015,2030.391357 
 C2071.144287,2010.392212 2100.779785,1984.989990 2122.092529,1950.824585 
 C2135.355713,1929.563354 2143.920166,1906.736450 2143.916504,1881.221802 
 C2143.880127,1633.305786 2143.879395,1385.389893 2143.869385,1137.473877 
 C2143.869141,1134.141724 2143.997559,1130.804932 2143.865234,1127.478271 
 C2143.263916,1112.363647 2131.776611,1101.129883 2116.737793,1101.305054 
 C2112.464600,1101.354980 2108.145508,1102.278076 2103.948975,1103.243652 
 C2085.798828,1107.420288 2067.686035,1111.760620 2049.465088,1114.233154 
 C2049.104736,1106.584106 2048.611572,1098.938477 2048.405029,1091.285278 
 C2046.928101,1036.550781 2040.141479,982.449829 2028.166016,929.061584 
 C1979.441406,711.842896 1867.570801,533.867493 1692.447632,396.853058 
 C1540.103760,277.661011 1366.214111,213.948914 1172.882324,203.622986 
 C1135.903076,201.647903 1098.997314,202.017563 1060.427002,204.515152 
 C1056.119629,204.955231 1051.817993,205.462189 1047.504150,205.825378 
 C979.538635,211.547363 912.892029,224.221802 848.028992,245.311432 
 C632.384827,315.426147 463.638123,446.490051 342.796753,638.165955 
 C257.350525,773.698792 212.230713,922.461060 204.505646,1082.419189 
 C202.399750,1126.024536 203.344254,1169.655396 207.608307,1213.205322 
 C211.573059,1253.698486 217.716049,1293.805298 226.939941,1333.403076 
 C260.648010,1478.109985 325.248108,1607.456055 421.108307,1720.984253 
 C513.812500,1830.775146 626.149353,1914.482300 757.747070,1972.238770 
 C833.515137,2005.492676 912.460022,2027.799194 994.340942,2039.447021 
 C1016.722046,2042.630737 1039.357300,2044.028564 1063.401489,2046.849854 
 C1114.426147,2050.500000 1165.389526,2049.301758 1215.644775,2046.246948 
 C1213.441650,2049.576660 1211.212646,2052.889648 1209.039673,2056.239014 
 C1198.810303,2072.004883 1190.913574,2088.719727 1186.960815,2107.267822 
 C1178.080688,2148.937500 1195.527710,2187.041260 1233.087036,2207.474121 
 C1257.782837,2220.908936 1284.667969,2225.312012 1312.190796,2225.138428 
 C1335.867676,2224.989258 1359.254761,2221.126465 1381.979248,2214.012939 
 C1436.025024,2197.095215 1482.827515,2169.014160 1518.458862,2124.265869 
 C1540.675781,2096.364502 1553.609863,2065.153076 1552.317139,2028.491333 
 C1551.331665,2000.546631 1552.359619,1972.531006 1553.640625,1944.261597 
 C1554.572632,1943.579956 1555.504639,1942.898193 1557.807251,1941.960449 
M1775.449829,2593.545410 
 C1739.749512,2559.421387 1697.801270,2537.844482 1648.708862,2530.930908 
 C1638.179321,2529.448242 1627.493164,2528.406982 1616.873047,2528.353760 
 C1563.575439,2528.086914 1510.275635,2528.186523 1456.976685,2528.227051 
 C1444.982788,2528.236328 1440.652466,2532.547119 1440.375977,2544.280029 
 C1440.321045,2546.610352 1440.367065,2548.943359 1440.367065,2551.275146 
 C1440.367065,2665.201904 1440.367188,2779.128662 1440.367310,2893.055420 
 C1440.367432,2923.369141 1440.296509,2953.683350 1440.403442,2983.996582 
 C1440.445679,2995.991699 1444.709106,3000.152100 1456.693237,3000.157715 
 C1510.325195,3000.182861 1563.961182,3000.489502 1617.586792,2999.893555 
 C1632.804443,2999.724854 1648.192871,2997.902588 1663.161133,2995.047363 
 C1761.818726,2976.225830 1876.913086,2869.553955 1843.179321,2710.501465 
 C1833.490845,2664.820557 1809.819702,2626.797852 1775.449829,2593.545410 
M358.098907,2645.000000 
 C358.097290,2611.339111 358.152252,2577.678223 358.062439,2544.017578 
 C358.032257,2532.717041 353.653473,2528.435791 342.349060,2528.228516 
 C336.018951,2528.112549 329.684967,2528.204834 323.352753,2528.205811 
 C310.355011,2528.208008 297.353821,2528.030029 284.360687,2528.276367 
 C275.124451,2528.451660 270.395599,2533.386475 269.928009,2542.533691 
 C269.843079,2544.196045 269.910004,2545.865967 269.910004,2547.532471 
 C269.910187,2692.174072 269.910492,2836.815430 269.912903,2981.457031 
 C269.912933,2983.456543 269.796173,2985.468750 269.977814,2987.453125 
 C270.672089,2995.035400 275.228668,2999.962891 282.774597,3000.052490 
 C303.767670,3000.301758 324.767883,3000.308838 345.760590,3000.043457 
 C353.071869,2999.950928 357.312286,2995.386719 357.999725,2988.142822 
 C358.250641,2985.498779 358.095886,2982.814453 358.096069,2980.148682 
 C358.099609,2924.491699 358.098846,2868.834717 358.098907,2813.177734 
 C358.098907,2809.591797 358.098907,2806.006104 358.098907,2802.066650 
 C431.739258,2802.066650 504.489899,2802.066650 577.953735,2802.066650 
 C577.953735,2805.895996 577.953674,2809.172852 577.953674,2812.449707 
 C577.953674,2868.106689 577.953308,2923.763672 577.954895,2979.420654 
 C577.954956,2982.086670 577.821167,2984.763916 578.011353,2987.416992 
 C578.576050,2995.293701 582.775146,2999.946777 590.575256,3000.046631 
 C611.234558,3000.311523 631.901733,3000.310547 652.561035,3000.045654 
 C659.715088,2999.954102 664.759705,2994.598389 665.580933,2987.327881 
 C665.878540,2984.693359 665.681519,2982.001221 665.681580,2979.335205 
 C665.684021,2836.026855 665.683594,2692.718262 665.682495,2549.409912 
 C665.682495,2547.077148 665.812195,2544.733887 665.640991,2542.413330 
 C665.022766,2534.035645 659.994324,2528.443359 651.745483,2528.323730 
 C631.420288,2528.028320 611.083862,2528.014404 590.759949,2528.358887 
 C582.641235,2528.496094 578.408325,2533.577881 577.975403,2541.971680 
 C577.855408,2544.298584 577.952148,2546.636475 577.952148,2548.969482 
 C577.952271,2602.293457 577.952759,2655.617676 577.953247,2708.941650 
 C577.953308,2712.445312 577.953247,2715.948975 577.953247,2719.308105 
 C503.948395,2719.308105 431.438721,2719.308105 358.098907,2719.308105 
 C358.098907,2694.843018 358.098907,2670.921387 358.098907,2645.000000 
M1354.176392,2761.274902 
 C1356.396118,2756.434570 1358.894531,2751.699707 1360.771973,2746.729980 
 C1363.758423,2738.824951 1360.400513,2732.833984 1352.219604,2730.973389 
 C1350.293579,2730.535400 1348.256836,2730.439453 1346.270386,2730.434814 
 C1329.606445,2730.395264 1312.941895,2730.498535 1296.278809,2730.383301 
 C1286.147583,2730.313477 1279.959351,2734.549561 1275.765015,2744.280273 
 C1256.140503,2789.807861 1235.864624,2835.054932 1215.797852,2880.391602 
 C1214.460571,2883.413086 1212.925781,2886.347168 1210.974609,2890.369629 
 C1209.026367,2886.270752 1207.444336,2883.108154 1206.002075,2879.883057 
 C1185.628174,2834.316650 1165.100586,2788.817383 1145.057861,2743.105713 
 C1140.995483,2733.840820 1135.272095,2729.900879 1125.151611,2730.252686 
 C1108.840698,2730.819824 1092.468628,2730.994385 1076.172485,2730.244141 
 C1058.824829,2729.445312 1054.594727,2739.746094 1061.221802,2753.499512 
 C1096.178955,2826.046143 1130.028198,2899.125732 1164.568115,2971.875244 
 C1167.713745,2978.500977 1168.051758,2983.574219 1164.794434,2990.394043 
 C1136.682007,3049.251709 1109.026001,3108.327881 1081.237671,3167.340332 
 C1078.401611,3173.363281 1075.313599,3179.286865 1072.844116,3185.457275 
 C1070.337769,3191.720459 1071.981445,3197.376221 1077.310791,3200.216553 
 C1080.074219,3201.689209 1083.619995,3202.216797 1086.823120,3202.253906 
 C1103.484741,3202.447021 1120.171631,3201.832764 1136.805664,3202.539062 
 C1146.627441,3202.956543 1152.805176,3199.596436 1156.005859,3190.439453 
 C1156.552368,3188.875977 1157.360229,3187.403076 1158.058105,3185.893311 
 C1223.254761,3044.826172 1288.452637,2903.759766 1354.176392,2761.274902 
M2074.238770,2975.235352 
 C2104.282227,2950.033203 2119.588623,2917.172607 2121.902344,2878.664795 
 C2123.598145,2850.442871 2123.141602,2822.076416 2123.189453,2793.773438 
 C2123.328125,2711.496582 2123.252930,2629.219482 2123.224365,2546.942383 
 C2123.218994,2531.777344 2119.570068,2528.233398 2104.249756,2528.208008 
 C2087.927490,2528.180908 2071.589355,2528.652832 2055.287842,2528.071045 
 C2039.594971,2527.511230 2033.826782,2531.327881 2033.898193,2549.687012 
 C2034.294312,2651.615723 2034.143921,2753.546875 2033.985962,2855.476807 
 C2033.972046,2864.411377 2033.570557,2873.573242 2031.615479,2882.238281 
 C2022.351440,2923.296387 1978.224976,2943.954590 1941.539795,2911.406494 
 C1939.299805,2909.418945 1937.322266,2907.132080 1935.052979,2905.181641 
 C1928.027954,2899.143799 1922.279663,2898.920654 1915.673340,2905.354248 
 C1903.982910,2916.739502 1892.504395,2928.356689 1881.287720,2940.208496 
 C1875.119751,2946.726074 1875.150635,2952.857422 1880.968506,2959.868408 
 C1897.526367,2979.822266 1917.663574,2995.188721 1942.959595,3001.560303 
 C1990.067871,3013.425537 2034.103149,3006.906982 2074.238770,2975.235352 
M906.999268,2878.839844 
 C938.986145,2878.839355 970.973022,2878.844482 1002.959900,2878.829346 
 C1005.955566,2878.827881 1008.963440,2878.850586 1011.944214,2878.603027 
 C1017.613647,2878.133057 1021.132751,2875.072021 1022.205872,2869.416016 
 C1022.702515,2866.798828 1023.187622,2864.170410 1023.481995,2861.525146 
 C1029.372437,2808.591064 1001.305847,2758.650146 953.178772,2735.690186 
 C909.592957,2714.896973 833.183411,2715.514160 787.049500,2780.770752 
 C763.915710,2813.493652 756.213623,2849.922852 762.293091,2888.946777 
 C770.210632,2939.770020 797.881287,2976.486328 845.284546,2997.152100 
 C870.388977,3008.096680 897.102844,3008.860840 923.860962,3005.264893 
 C952.327637,3001.439941 977.487122,2989.992920 998.654236,2970.328125 
 C1005.907837,2963.589355 1006.159973,2959.990723 999.834290,2952.559570 
 C991.197754,2942.414307 982.463135,2932.348633 973.583923,2922.415527 
 C968.595825,2916.835449 964.332947,2916.058838 957.599487,2919.200684 
 C954.602783,2920.598633 951.833984,2922.503906 949.020020,2924.271729 
 C935.124207,2933.001221 919.857483,2937.204834 903.574341,2937.575195 
 C872.510315,2938.281494 846.330017,2918.804199 839.078735,2889.709961 
 C838.248718,2886.380127 838.224548,2882.849609 837.760498,2878.839844 
 C861.090454,2878.839844 883.044800,2878.839844 906.999268,2878.839844 
z"
      />
      <path
        fill="#596B75"
        opacity="1.000000"
        stroke="none"
        d="
M1061.875854,2046.245850 
 C1039.357300,2044.028564 1016.722046,2042.630737 994.340942,2039.447021 
 C912.460022,2027.799194 833.515137,2005.492676 757.747070,1972.238770 
 C626.149353,1914.482300 513.812500,1830.775146 421.108307,1720.984253 
 C325.248108,1607.456055 260.648010,1478.109985 226.939941,1333.403076 
 C217.716049,1293.805298 211.573059,1253.698486 207.608307,1213.205322 
 C203.344254,1169.655396 202.399750,1126.024536 204.505646,1082.419189 
 C212.230713,922.461060 257.350525,773.698792 342.796753,638.165955 
 C463.638123,446.490051 632.384827,315.426147 848.028992,245.311432 
 C912.892029,224.221802 979.538635,211.547363 1047.504150,205.825378 
 C1051.817993,205.462189 1056.119629,204.955231 1061.509888,205.359619 
 C1064.045044,206.796906 1065.454712,207.748077 1066.956177,207.926285 
 C1079.766724,209.446716 1092.724731,210.104797 1105.389771,212.395996 
 C1133.693726,217.516327 1161.989624,222.534409 1190.034912,229.269531 
 C1219.778076,236.412430 1248.889771,245.426437 1277.695068,255.282822 
 C1303.418213,264.084595 1328.654053,274.617126 1353.371826,285.980591 
 C1393.585571,304.467865 1432.561035,325.428009 1469.581787,349.817627 
 C1493.973022,365.886749 1517.775757,382.725037 1540.657227,400.966766 
 C1564.296021,419.812134 1587.290894,439.346191 1609.067749,460.254517 
 C1629.451782,479.825531 1649.472290,499.798157 1667.107910,522.006775 
 C1680.915405,539.394714 1695.596802,556.113586 1708.891235,573.877136 
 C1722.597046,592.190430 1735.873291,610.924744 1747.927124,630.349976 
 C1763.507202,655.457581 1778.827515,680.856812 1792.155518,707.195190 
 C1805.771606,734.103027 1817.889160,761.895447 1828.739868,790.041809 
 C1840.314331,820.065430 1850.821777,850.619385 1859.634888,881.557190 
 C1869.876953,917.511169 1878.131226,954.047302 1882.304932,991.360291 
 C1884.545654,1011.393555 1888.102539,1031.300415 1889.761963,1051.371948 
 C1891.374634,1070.877808 1891.493652,1090.517212 1891.944580,1110.105469 
 C1892.289795,1125.096924 1892.130005,1140.099854 1891.557129,1155.434326 
 C1887.706421,1156.589600 1884.497559,1157.426270 1881.279297,1158.224731 
 C1801.762817,1177.950928 1722.259766,1197.731689 1642.718994,1217.359741 
 C1619.226929,1223.156860 1595.628052,1228.521362 1572.150146,1233.288330 
 C1579.641235,1200.760498 1584.984497,1168.762817 1584.063232,1136.018311 
 C1583.418335,1113.098633 1584.126221,1089.993774 1581.358154,1067.322144 
 C1578.559082,1044.396240 1573.502930,1021.539795 1567.199707,999.286194 
 C1547.572876,929.993774 1512.563843,868.972107 1464.343506,815.627258 
 C1448.285522,797.862732 1430.558594,781.715454 1411.790405,766.745239 
 C1391.999756,750.959290 1371.200806,736.613647 1348.950073,724.643799 
 C1319.345215,708.717712 1288.776123,695.051208 1256.298584,685.566345 
 C1226.879028,676.974609 1196.946533,670.880249 1166.630859,668.652832 
 C1147.327637,667.234558 1127.881226,665.109558 1108.047607,667.323853 
 C1088.015015,669.560242 1067.820923,670.422546 1047.829468,673.798584 
 C1007.610046,680.590515 969.045288,692.600647 932.250000,710.015564 
 C896.972107,726.712280 864.092896,747.209778 834.041992,772.400269 
 C813.068542,789.981384 793.135498,808.541016 775.852783,829.634338 
 C749.465332,861.839966 726.694031,896.420898 709.487488,934.604309 
 C698.666016,958.618652 689.706482,983.182373 682.820374,1008.543213 
 C674.982727,1037.409180 669.953491,1066.885742 668.344727,1096.647827 
 C666.226013,1135.843750 668.312195,1174.984863 676.035034,1213.651611 
 C683.089722,1248.973022 693.796143,1283.135010 708.969604,1315.803711 
 C726.699402,1353.976440 748.549500,1389.541016 776.181580,1421.499268 
 C795.168030,1443.458252 815.436462,1464.145264 837.922302,1482.385864 
 C872.715271,1510.609741 911.133606,1533.027222 952.545654,1550.264282 
 C997.979370,1569.175293 1045.438599,1580.290894 1094.318115,1583.514038 
 C1125.725952,1585.584961 1157.373779,1584.990601 1188.839600,1580.269165 
 C1217.152710,1576.020508 1244.923340,1569.799194 1271.995605,1560.793579 
 C1300.427490,1551.335693 1327.875854,1539.397095 1353.739014,1524.080688 
 C1376.636597,1510.520630 1398.805176,1495.932373 1418.876343,1478.331177 
 C1426.083618,1472.010620 1433.558350,1465.995239 1441.165283,1460.753052 
 C1441.496948,1462.993652 1441.641724,1464.321655 1441.641724,1465.649780 
 C1441.653564,1614.751709 1441.661011,1763.853760 1441.610474,1912.955688 
 C1441.609375,1915.900513 1440.922241,1918.844971 1439.785645,1921.899170 
 C1428.563599,1927.398438 1418.043579,1932.664551 1407.725952,1938.301392 
 C1406.029419,1939.228271 1405.222290,1941.782593 1403.172607,1943.778931 
 C1364.789917,1948.175293 1329.540405,1959.701172 1296.755981,1978.313965 
 C1282.921631,1986.168335 1269.924072,1995.495972 1255.718262,2004.119629 
 C1251.379883,2005.009888 1247.849609,2005.865112 1244.362793,2006.871704 
 C1231.637329,2010.545410 1219.014282,2014.610352 1206.194092,2017.912842 
 C1162.641357,2029.132080 1118.727295,2038.432007 1073.788452,2042.222412 
 C1069.733398,2042.564331 1065.842773,2044.858398 1061.875854,2046.245850 
z"
      />
      <path
        fill="#FE6470"
        opacity="1.000000"
        stroke="none"
        d="
M1256.546143,2004.154053 
 C1269.924072,1995.495972 1282.921631,1986.168335 1296.755981,1978.313965 
 C1329.540405,1959.701172 1364.789917,1948.175293 1404.032227,1943.854370 
 C1417.114502,1943.031738 1428.505859,1942.327759 1439.868408,1941.625610 
 C1442.691284,1934.745850 1441.599243,1928.266846 1440.555176,1921.789673 
 C1440.922241,1918.844971 1441.609375,1915.900513 1441.610474,1912.955688 
 C1441.661011,1763.853760 1441.653564,1614.751709 1441.641724,1465.649780 
 C1441.641724,1464.321655 1441.496948,1462.993652 1441.396973,1459.851196 
 C1441.430908,1453.710693 1441.535400,1449.384521 1441.536987,1445.058350 
 C1441.555298,1393.456909 1441.494629,1341.855469 1441.603149,1290.254272 
 C1441.636719,1274.257568 1448.498413,1265.535889 1463.872437,1261.562134 
 C1479.320923,1257.568970 1494.850586,1253.890869 1510.315796,1249.961182 
 C1530.917847,1244.726196 1551.491943,1239.381470 1572.078125,1234.083984 
 C1595.628052,1228.521362 1619.226929,1223.156860 1642.718994,1217.359741 
 C1722.259766,1197.731689 1801.762817,1177.950928 1881.279297,1158.224731 
 C1884.497559,1157.426270 1887.706421,1156.589600 1892.400757,1155.292236 
 C1921.239502,1148.205322 1948.587646,1141.558472 1975.957764,1135.002441 
 C1994.618286,1130.532471 2013.309448,1126.189819 2032.001221,1122.744141 
 C2032.020874,1215.300659 2032.022827,1306.903076 2032.043579,1398.505615 
 C2032.044556,1402.498901 2032.217529,1406.492310 2032.310303,1410.485596 
 C2022.616333,1412.894409 2012.922485,1415.303345 2001.695435,1417.980957 
 C1955.254272,1429.290405 1910.329956,1440.265869 1865.448486,1451.413330 
 C1852.880981,1454.534790 1840.439087,1458.162231 1827.102661,1461.574951 
 C1823.649048,1462.042114 1820.992676,1462.331177 1818.421265,1462.964966 
 C1743.268555,1481.490845 1668.120728,1500.036377 1592.982178,1518.619507 
 C1579.519409,1521.948975 1566.100708,1525.457520 1552.079346,1529.031738 
 C1552.079346,1532.994141 1552.079346,1536.271484 1552.079224,1539.548828 
 C1552.078857,1636.406006 1552.073975,1733.263184 1552.098389,1830.120361 
 C1552.099243,1833.436646 1552.395752,1836.753052 1552.388184,1840.988037 
 C1552.305542,1875.262695 1552.389160,1908.618408 1552.383179,1942.618164 
 C1552.359619,1943.690308 1552.425781,1944.118530 1552.491943,1944.546875 
 C1552.359619,1972.531006 1551.331665,2000.546631 1552.317139,2028.491333 
 C1553.609863,2065.153076 1540.675781,2096.364502 1518.458862,2124.265869 
 C1482.827515,2169.014160 1436.025024,2197.095215 1381.979248,2214.012939 
 C1359.254761,2221.126465 1335.867676,2224.989258 1312.190796,2225.138428 
 C1284.667969,2225.312012 1257.782837,2220.908936 1233.087036,2207.474121 
 C1195.527710,2187.041260 1178.080688,2148.937500 1186.960815,2107.267822 
 C1190.913574,2088.719727 1198.810303,2072.004883 1209.039673,2056.239014 
 C1211.212646,2052.889648 1213.441650,2049.576660 1216.376953,2045.169800 
 C1230.254761,2030.779785 1243.400391,2017.466919 1256.546143,2004.154053 
z"
      />
      <path
        fill="#475B62"
        opacity="1.000000"
        stroke="none"
        d="
M2031.987061,1121.790283 
 C2013.309448,1126.189819 1994.618286,1130.532471 1975.957764,1135.002441 
 C1948.587646,1141.558472 1921.239502,1148.205322 1893.038330,1154.955688 
 C1892.130005,1140.099854 1892.289795,1125.096924 1891.944580,1110.105469 
 C1891.493652,1090.517212 1891.374634,1070.877808 1889.761963,1051.371948 
 C1888.102539,1031.300415 1884.545654,1011.393555 1882.304932,991.360291 
 C1878.131226,954.047302 1869.876953,917.511169 1859.634888,881.557190 
 C1850.821777,850.619385 1840.314331,820.065430 1828.739868,790.041809 
 C1817.889160,761.895447 1805.771606,734.103027 1792.155518,707.195190 
 C1778.827515,680.856812 1763.507202,655.457581 1747.927124,630.349976 
 C1735.873291,610.924744 1722.597046,592.190430 1708.891235,573.877136 
 C1695.596802,556.113586 1680.915405,539.394714 1667.107910,522.006775 
 C1649.472290,499.798157 1629.451782,479.825531 1609.067749,460.254517 
 C1587.290894,439.346191 1564.296021,419.812134 1540.657227,400.966766 
 C1517.775757,382.725037 1493.973022,365.886749 1469.581787,349.817627 
 C1432.561035,325.428009 1393.585571,304.467865 1353.371826,285.980591 
 C1328.654053,274.617126 1303.418213,264.084595 1277.695068,255.282822 
 C1248.889771,245.426437 1219.778076,236.412430 1190.034912,229.269531 
 C1161.989624,222.534409 1133.693726,217.516327 1105.389771,212.395996 
 C1092.724731,210.104797 1079.766724,209.446716 1066.956177,207.926285 
 C1065.454712,207.748077 1064.045044,206.796906 1062.343018,205.506073 
 C1098.997314,202.017563 1135.903076,201.647903 1172.882324,203.622986 
 C1366.214111,213.948914 1540.103760,277.661011 1692.447632,396.853058 
 C1867.570801,533.867493 1979.441406,711.842896 2028.166016,929.061584 
 C2040.141479,982.449829 2046.928101,1036.550781 2048.405029,1091.285278 
 C2048.611572,1098.938477 2049.104736,1106.584106 2048.872070,1115.452026 
 C2042.848389,1118.377441 2037.417725,1120.083984 2031.987061,1121.790283 
z"
      />
      <path
        fill="white"
        opacity="1.000000"
        stroke="none"
        d="
M1775.964966,2594.035400 
 C1809.819702,2626.797852 1833.490845,2664.820557 1843.179321,2710.501465 
 C1876.913086,2869.553955 1761.818726,2976.225830 1663.161133,2995.047363 
 C1648.192871,2997.902588 1632.804443,2999.724854 1617.586792,2999.893555 
 C1563.961182,3000.489502 1510.325195,3000.182861 1456.693237,3000.157715 
 C1444.709106,3000.152100 1440.445679,2995.991699 1440.403442,2983.996582 
 C1440.296509,2953.683350 1440.367432,2923.369141 1440.367310,2893.055420 
 C1440.367188,2779.128662 1440.367065,2665.201904 1440.367065,2551.275146 
 C1440.367065,2548.943359 1440.321045,2546.610352 1440.375977,2544.280029 
 C1440.652466,2532.547119 1444.982788,2528.236328 1456.976685,2528.227051 
 C1510.275635,2528.186523 1563.575439,2528.086914 1616.873047,2528.353760 
 C1627.493164,2528.406982 1638.179321,2529.448242 1648.708862,2530.930908 
 C1697.801270,2537.844482 1739.749512,2559.421387 1775.964966,2594.035400 
M1598.945801,2609.919189 
 C1575.401001,2609.919189 1551.856323,2609.919189 1527.960693,2609.919189 
 C1527.960693,2712.965332 1527.960693,2815.029297 1527.960693,2917.211182 
 C1529.269775,2917.538818 1530.190186,2917.971191 1531.109985,2917.970215 
 C1559.082275,2917.938721 1587.085083,2918.664062 1615.018799,2917.591553 
 C1648.655884,2916.299805 1678.659180,2904.550293 1704.055298,2882.006348 
 C1742.377686,2847.987793 1758.084473,2804.619629 1755.625244,2754.308594 
 C1753.142944,2703.527100 1731.189819,2662.997314 1688.991699,2634.156738 
 C1662.517822,2616.062988 1632.630615,2609.458984 1598.945801,2609.919189 
z"
      />
      <path
        fill="#FD4855"
        opacity="1.000000"
        stroke="none"
        d="
M2032.001221,1122.744263 
 C2037.417725,1120.083984 2042.848389,1118.377441 2048.919434,1116.356201 
 C2067.686035,1111.760620 2085.798828,1107.420288 2103.948975,1103.243652 
 C2108.145508,1102.278076 2112.464600,1101.354980 2116.737793,1101.305054 
 C2131.776611,1101.129883 2143.263916,1112.363647 2143.865234,1127.478271 
 C2143.997559,1130.804932 2143.869141,1134.141724 2143.869385,1137.473877 
 C2143.879395,1385.389893 2143.880127,1633.305786 2143.916504,1881.221802 
 C2143.920166,1906.736450 2135.355713,1929.563354 2122.092529,1950.824585 
 C2100.779785,1984.989990 2071.144287,2010.392212 2036.640015,2030.391357 
 C2002.812744,2049.998047 1966.552002,2062.918213 1927.614624,2067.200928 
 C1903.354980,2069.868896 1879.040527,2069.484131 1854.694336,2061.820557 
 C1857.293457,2059.072510 1860.245239,2056.949951 1863.538086,2055.946045 
 C1883.651855,2049.814697 1903.091064,2041.894531 1921.096313,2031.188721 
 C1936.720581,2021.898560 1952.338013,2012.071655 1966.110229,2000.316528 
 C1986.336060,1983.052734 2004.452759,1963.392822 2016.973999,1939.517212 
 C2025.650879,1922.972412 2032.003540,1905.570557 2032.026978,1886.459351 
 C2032.067749,1853.174927 2032.157837,1819.890625 2032.227295,1786.606201 
 C2032.358521,1784.953247 2032.604736,1783.300171 2032.604614,1781.647217 
 C2032.589966,1658.627441 2032.555908,1535.607788 2032.416748,1411.536865 
 C2032.217529,1406.492310 2032.044556,1402.498901 2032.043579,1398.505615 
 C2032.022827,1306.903076 2032.020874,1215.300659 2032.001221,1122.744263 
z"
      />
      <path
        fill="white"
        opacity="1.000000"
        stroke="none"
        d="
M358.098907,2646.000000 
 C358.098907,2670.921387 358.098907,2694.843018 358.098907,2719.308105 
 C431.438721,2719.308105 503.948395,2719.308105 577.953247,2719.308105 
 C577.953247,2715.948975 577.953308,2712.445312 577.953247,2708.941650 
 C577.952759,2655.617676 577.952271,2602.293457 577.952148,2548.969482 
 C577.952148,2546.636475 577.855408,2544.298584 577.975403,2541.971680 
 C578.408325,2533.577881 582.641235,2528.496094 590.759949,2528.358887 
 C611.083862,2528.014404 631.420288,2528.028320 651.745483,2528.323730 
 C659.994324,2528.443359 665.022766,2534.035645 665.640991,2542.413330 
 C665.812195,2544.733887 665.682495,2547.077148 665.682495,2549.409912 
 C665.683594,2692.718262 665.684021,2836.026855 665.681580,2979.335205 
 C665.681519,2982.001221 665.878540,2984.693359 665.580933,2987.327881 
 C664.759705,2994.598389 659.715088,2999.954102 652.561035,3000.045654 
 C631.901733,3000.310547 611.234558,3000.311523 590.575256,3000.046631 
 C582.775146,2999.946777 578.576050,2995.293701 578.011353,2987.416992 
 C577.821167,2984.763916 577.954956,2982.086670 577.954895,2979.420654 
 C577.953308,2923.763672 577.953674,2868.106689 577.953674,2812.449707 
 C577.953674,2809.172852 577.953735,2805.895996 577.953735,2802.066650 
 C504.489899,2802.066650 431.739258,2802.066650 358.098907,2802.066650 
 C358.098907,2806.006104 358.098907,2809.591797 358.098907,2813.177734 
 C358.098846,2868.834717 358.099609,2924.491699 358.096069,2980.148682 
 C358.095886,2982.814453 358.250641,2985.498779 357.999725,2988.142822 
 C357.312286,2995.386719 353.071869,2999.950928 345.760590,3000.043457 
 C324.767883,3000.308838 303.767670,3000.301758 282.774597,3000.052490 
 C275.228668,2999.962891 270.672089,2995.035400 269.977814,2987.453125 
 C269.796173,2985.468750 269.912933,2983.456543 269.912903,2981.457031 
 C269.910492,2836.815430 269.910187,2692.174072 269.910004,2547.532471 
 C269.910004,2545.865967 269.843079,2544.196045 269.928009,2542.533691 
 C270.395599,2533.386475 275.124451,2528.451660 284.360687,2528.276367 
 C297.353821,2528.030029 310.355011,2528.208008 323.352753,2528.205811 
 C329.684967,2528.204834 336.018951,2528.112549 342.349060,2528.228516 
 C353.653473,2528.435791 358.032257,2532.717041 358.062439,2544.017578 
 C358.152252,2577.678223 358.097290,2611.339111 358.098907,2646.000000 
z"
      />
      <path
        fill="#475B61"
        opacity="1.000000"
        stroke="none"
        d="
M1552.472778,1941.974243 
 C1552.389160,1908.618408 1552.305542,1875.262695 1553.032715,1840.876587 
 C1557.116089,1837.070679 1560.437744,1834.350708 1563.652954,1831.510132 
 C1580.543335,1816.587036 1598.055176,1802.292480 1614.102661,1786.510010 
 C1631.120361,1769.773315 1646.951782,1751.804443 1662.892944,1734.008057 
 C1673.715088,1721.926392 1684.040161,1709.380493 1694.204712,1696.734375 
 C1703.124146,1685.637207 1711.691650,1674.244141 1720.097534,1662.749878 
 C1742.262939,1632.440552 1762.098877,1600.614624 1779.999390,1567.643677 
 C1790.790894,1547.766846 1800.878662,1527.462891 1810.289429,1506.896240 
 C1817.026001,1492.173706 1822.115356,1476.697388 1827.939575,1461.557373 
 C1840.439087,1458.162231 1852.880981,1454.534790 1865.448486,1451.413330 
 C1910.329956,1440.265869 1955.254272,1429.290405 2000.934082,1418.217529 
 C1986.721436,1464.920288 1967.420532,1509.856567 1944.464111,1553.179688 
 C1855.026733,1721.965088 1726.137573,1851.596069 1556.464355,1941.998657 
 C1554.238647,1942.015991 1553.355713,1941.995117 1552.472778,1941.974243 
z"
      />
      <path
        fill="white"
        opacity="1.000000"
        stroke="none"
        d="
M1353.913330,2761.983887 
 C1288.452637,2903.759766 1223.254761,3044.826172 1158.058105,3185.893311 
 C1157.360229,3187.403076 1156.552368,3188.875977 1156.005859,3190.439453 
 C1152.805176,3199.596436 1146.627441,3202.956543 1136.805664,3202.539062 
 C1120.171631,3201.832764 1103.484741,3202.447021 1086.823120,3202.253906 
 C1083.619995,3202.216797 1080.074219,3201.689209 1077.310791,3200.216553 
 C1071.981445,3197.376221 1070.337769,3191.720459 1072.844116,3185.457275 
 C1075.313599,3179.286865 1078.401611,3173.363281 1081.237671,3167.340332 
 C1109.026001,3108.327881 1136.682007,3049.251709 1164.794434,2990.394043 
 C1168.051758,2983.574219 1167.713745,2978.500977 1164.568115,2971.875244 
 C1130.028198,2899.125732 1096.178955,2826.046143 1061.221802,2753.499512 
 C1054.594727,2739.746094 1058.824829,2729.445312 1076.172485,2730.244141 
 C1092.468628,2730.994385 1108.840698,2730.819824 1125.151611,2730.252686 
 C1135.272095,2729.900879 1140.995483,2733.840820 1145.057861,2743.105713 
 C1165.100586,2788.817383 1185.628174,2834.316650 1206.002075,2879.883057 
 C1207.444336,2883.108154 1209.026367,2886.270752 1210.974609,2890.369629 
 C1212.925781,2886.347168 1214.460571,2883.413086 1215.797852,2880.391602 
 C1235.864624,2835.054932 1256.140503,2789.807861 1275.765015,2744.280273 
 C1279.959351,2734.549561 1286.147583,2730.313477 1296.278809,2730.383301 
 C1312.941895,2730.498535 1329.606445,2730.395264 1346.270386,2730.434814 
 C1348.256836,2730.439453 1350.293579,2730.535400 1352.219604,2730.973389 
 C1360.400513,2732.833984 1363.758423,2738.824951 1360.771973,2746.729980 
 C1358.894531,2751.699707 1356.396118,2756.434570 1353.913330,2761.983887 
z"
      />
      <path
        fill="white"
        opacity="1.000000"
        stroke="none"
        d="
M2073.687988,2975.683594 
 C2034.103149,3006.906982 1990.067871,3013.425537 1942.959595,3001.560303 
 C1917.663574,2995.188721 1897.526367,2979.822266 1880.968506,2959.868408 
 C1875.150635,2952.857422 1875.119751,2946.726074 1881.287720,2940.208496 
 C1892.504395,2928.356689 1903.982910,2916.739502 1915.673340,2905.354248 
 C1922.279663,2898.920654 1928.027954,2899.143799 1935.052979,2905.181641 
 C1937.322266,2907.132080 1939.299805,2909.418945 1941.539795,2911.406494 
 C1978.224976,2943.954590 2022.351440,2923.296387 2031.615479,2882.238281 
 C2033.570557,2873.573242 2033.972046,2864.411377 2033.985962,2855.476807 
 C2034.143921,2753.546875 2034.294312,2651.615723 2033.898193,2549.687012 
 C2033.826782,2531.327881 2039.594971,2527.511230 2055.287842,2528.071045 
 C2071.589355,2528.652832 2087.927490,2528.180908 2104.249756,2528.208008 
 C2119.570068,2528.233398 2123.218994,2531.777344 2123.224365,2546.942383 
 C2123.252930,2629.219482 2123.328125,2711.496582 2123.189453,2793.773438 
 C2123.141602,2822.076416 2123.598145,2850.442871 2121.902344,2878.664795 
 C2119.588623,2917.172607 2104.282227,2950.033203 2073.687988,2975.683594 
z"
      />
      <path
        fill="#FE6571"
        opacity="1.000000"
        stroke="none"
        d="
M2031.279053,1786.540771 
 C2032.157837,1819.890625 2032.067749,1853.174927 2032.026978,1886.459351 
 C2032.003540,1905.570557 2025.650879,1922.972412 2016.973999,1939.517212 
 C2004.452759,1963.392822 1986.336060,1983.052734 1966.110229,2000.316528 
 C1952.338013,2012.071655 1936.720581,2021.898560 1921.096313,2031.188721 
 C1903.091064,2041.894531 1883.651855,2049.814697 1863.538086,2055.946045 
 C1860.245239,2056.949951 1857.293457,2059.072510 1854.057861,2061.405273 
 C1801.303589,2050.782959 1762.570801,2003.603760 1780.917603,1942.040283 
 C1788.393921,1916.953491 1801.725708,1895.210938 1819.286743,1876.231201 
 C1862.814819,1829.187012 1916.789307,1801.103271 1979.686646,1789.820312 
 C1996.434204,1786.816162 2013.321777,1785.657349 2031.279053,1786.540771 
z"
      />
      <path
        fill="white"
        opacity="1.000000"
        stroke="none"
        d="
M905.999207,2878.839844 
 C883.044800,2878.839844 861.090454,2878.839844 837.760498,2878.839844 
 C838.224548,2882.849609 838.248718,2886.380127 839.078735,2889.709961 
 C846.330017,2918.804199 872.510315,2938.281494 903.574341,2937.575195 
 C919.857483,2937.204834 935.124207,2933.001221 949.020020,2924.271729 
 C951.833984,2922.503906 954.602783,2920.598633 957.599487,2919.200684 
 C964.332947,2916.058838 968.595825,2916.835449 973.583923,2922.415527 
 C982.463135,2932.348633 991.197754,2942.414307 999.834290,2952.559570 
 C1006.159973,2959.990723 1005.907837,2963.589355 998.654236,2970.328125 
 C977.487122,2989.992920 952.327637,3001.439941 923.860962,3005.264893 
 C897.102844,3008.860840 870.388977,3008.096680 845.284546,2997.152100 
 C797.881287,2976.486328 770.210632,2939.770020 762.293091,2888.946777 
 C756.213623,2849.922852 763.915710,2813.493652 787.049500,2780.770752 
 C833.183411,2715.514160 909.592957,2714.896973 953.178772,2735.690186 
 C1001.305847,2758.650146 1029.372437,2808.591064 1023.481995,2861.525146 
 C1023.187622,2864.170410 1022.702515,2866.798828 1022.205872,2869.416016 
 C1021.132751,2875.072021 1017.613647,2878.133057 1011.944214,2878.603027 
 C1008.963440,2878.850586 1005.955566,2878.827881 1002.959900,2878.829346 
 C970.973022,2878.844482 938.986145,2878.839355 905.999207,2878.839844 
M889.740234,2786.098877 
 C863.527710,2790.910156 844.285522,2810.194092 844.670959,2831.433838 
 C877.828857,2831.433838 910.988464,2831.433838 944.462891,2831.433838 
 C945.045105,2823.791016 942.621033,2817.305176 938.929016,2811.390381 
 C928.026184,2793.922607 912.549072,2784.691406 889.740234,2786.098877 
z"
      />
      <path
        fill="#495C63"
        opacity="1.000000"
        stroke="none"
        d="
M1255.718262,2004.119629 
 C1243.400391,2017.466919 1230.254761,2030.779785 1216.710815,2044.504150 
 C1165.389526,2049.301758 1114.426147,2050.500000 1062.638672,2046.547852 
 C1065.842773,2044.858398 1069.733398,2042.564331 1073.788452,2042.222412 
 C1118.727295,2038.432007 1162.641357,2029.132080 1206.194092,2017.912842 
 C1219.014282,2014.610352 1231.637329,2010.545410 1244.362793,2006.871704 
 C1247.849609,2005.865112 1251.379883,2005.009888 1255.718262,2004.119629 
z"
      />
      <path
        fill="#5A6B75"
        opacity="1.000000"
        stroke="none"
        d="
M1552.383179,1942.618164 
 C1553.355713,1941.995117 1554.238647,1942.015991 1555.779053,1942.126709 
 C1555.504639,1942.898193 1554.572632,1943.579956 1553.066284,1944.404297 
 C1552.425781,1944.118530 1552.359619,1943.690308 1552.383179,1942.618164 
z"
      />
      <path
        fill="#374949"
        opacity="1.000000"
        stroke="none"
        d="
M1572.150146,1233.288330 
 C1551.491943,1239.381470 1530.917847,1244.726196 1510.315796,1249.961182 
 C1494.850586,1253.890869 1479.320923,1257.568970 1463.872437,1261.562134 
 C1448.498413,1265.535889 1441.636719,1274.257568 1441.603149,1290.254272 
 C1441.494629,1341.855469 1441.555298,1393.456909 1441.536987,1445.058350 
 C1441.535400,1449.384521 1441.430908,1453.710693 1441.142578,1458.938721 
 C1433.558350,1465.995239 1426.083618,1472.010620 1418.876343,1478.331177 
 C1398.805176,1495.932373 1376.636597,1510.520630 1353.739014,1524.080688 
 C1327.875854,1539.397095 1300.427490,1551.335693 1271.995605,1560.793579 
 C1244.923340,1569.799194 1217.152710,1576.020508 1188.839600,1580.269165 
 C1157.373779,1584.990601 1125.725952,1585.584961 1094.318115,1583.514038 
 C1045.438599,1580.290894 997.979370,1569.175293 952.545654,1550.264282 
 C911.133606,1533.027222 872.715271,1510.609741 837.922302,1482.385864 
 C815.436462,1464.145264 795.168030,1443.458252 776.181580,1421.499268 
 C748.549500,1389.541016 726.699402,1353.976440 708.969604,1315.803711 
 C693.796143,1283.135010 683.089722,1248.973022 676.035034,1213.651611 
 C668.312195,1174.984863 666.226013,1135.843750 668.344727,1096.647827 
 C669.953491,1066.885742 674.982727,1037.409180 682.820374,1008.543213 
 C689.706482,983.182373 698.666016,958.618652 709.487488,934.604309 
 C726.694031,896.420898 749.465332,861.839966 775.852783,829.634338 
 C793.135498,808.541016 813.068542,789.981384 834.041992,772.400269 
 C864.092896,747.209778 896.972107,726.712280 932.250000,710.015564 
 C969.045288,692.600647 1007.610046,680.590515 1047.829468,673.798584 
 C1067.820923,670.422546 1088.015015,669.560242 1108.047607,667.323853 
 C1127.881226,665.109558 1147.327637,667.234558 1166.630859,668.652832 
 C1196.946533,670.880249 1226.879028,676.974609 1256.298584,685.566345 
 C1288.776123,695.051208 1319.345215,708.717712 1348.950073,724.643799 
 C1371.200806,736.613647 1391.999756,750.959290 1411.790405,766.745239 
 C1430.558594,781.715454 1448.285522,797.862732 1464.343506,815.627258 
 C1512.563843,868.972107 1547.572876,929.993774 1567.199707,999.286194 
 C1573.502930,1021.539795 1578.559082,1044.396240 1581.358154,1067.322144 
 C1584.126221,1089.993774 1583.418335,1113.098633 1584.063232,1136.018311 
 C1584.984497,1168.762817 1579.641235,1200.760498 1572.150146,1233.288330 
M985.891296,962.892639 
 C924.553528,1018.864929 900.223511,1087.904663 916.075928,1169.411011 
 C937.074890,1277.378906 1043.205933,1359.410034 1165.098755,1337.128296 
 C1285.973389,1315.032593 1362.580566,1198.163818 1335.997437,1078.188110 
 C1315.639526,986.307495 1233.327881,910.128235 1124.496704,910.539368 
 C1072.717407,910.734985 1026.653809,928.345398 985.891296,962.892639 
z"
      />
      <path
        fill="#465A61"
        opacity="1.000000"
        stroke="none"
        d="
M1439.785645,1921.899170 
 C1441.599243,1928.266846 1442.691284,1934.745850 1439.868408,1941.625610 
 C1428.505859,1942.327759 1417.114502,1943.031738 1404.863525,1943.660278 
 C1405.222290,1941.782593 1406.029419,1939.228271 1407.725952,1938.301392 
 C1418.043579,1932.664551 1428.563599,1927.398438 1439.785645,1921.899170 
z"
      />
      <path
        fill="#5A6B75"
        opacity="1.000000"
        stroke="none"
        d="
M1827.102661,1461.574951 
 C1822.115356,1476.697388 1817.026001,1492.173706 1810.289429,1506.896240 
 C1800.878662,1527.462891 1790.790894,1547.766846 1779.999390,1567.643677 
 C1762.098877,1600.614624 1742.262939,1632.440552 1720.097534,1662.749878 
 C1711.691650,1674.244141 1703.124146,1685.637207 1694.204712,1696.734375 
 C1684.040161,1709.380493 1673.715088,1721.926392 1662.892944,1734.008057 
 C1646.951782,1751.804443 1631.120361,1769.773315 1614.102661,1786.510010 
 C1598.055176,1802.292480 1580.543335,1816.587036 1563.652954,1831.510132 
 C1560.437744,1834.350708 1557.116089,1837.070679 1553.198975,1839.957764 
 C1552.395752,1836.753052 1552.099243,1833.436646 1552.098389,1830.120361 
 C1552.073975,1733.263184 1552.078857,1636.406006 1552.079224,1539.548828 
 C1552.079346,1536.271484 1552.079346,1532.994141 1552.079346,1529.031738 
 C1566.100708,1525.457520 1579.519409,1521.948975 1592.982178,1518.619507 
 C1668.120728,1500.036377 1743.268555,1481.490845 1818.421265,1462.964966 
 C1820.992676,1462.331177 1823.649048,1462.042114 1827.102661,1461.574951 
z"
      />
      <path
        fill="#FEFEFE"
        opacity="0.000000"
        stroke="none"
        d="
M1599.934082,2609.919922 
 C1632.630615,2609.458984 1662.517822,2616.062988 1688.991699,2634.156738 
 C1731.189819,2662.997314 1753.142944,2703.527100 1755.625244,2754.308594 
 C1758.084473,2804.619629 1742.377686,2847.987793 1704.055298,2882.006348 
 C1678.659180,2904.550293 1648.655884,2916.299805 1615.018799,2917.591553 
 C1587.085083,2918.664062 1559.082275,2917.938721 1531.109985,2917.970215 
 C1530.190186,2917.971191 1529.269775,2917.538818 1527.960693,2917.211182 
 C1527.960693,2815.029297 1527.960693,2712.965332 1527.960693,2609.919189 
 C1551.856323,2609.919189 1575.401001,2609.919189 1599.934082,2609.919922 
z"
      />
      <path
        fill="#F9F9F9"
        opacity="0.000000"
        stroke="none"
        d="
M890.626831,2786.044434 
 C912.549072,2784.691406 928.026184,2793.922607 938.929016,2811.390381 
 C942.621033,2817.305176 945.045105,2823.791016 944.462891,2831.433838 
 C910.988464,2831.433838 877.828857,2831.433838 844.670959,2831.433838 
 C844.285522,2810.194092 863.527710,2790.910156 890.626831,2786.044434 
z"
      />
      <path
        fill="#FEFEFE"
        opacity="0.000000"
        stroke="none"
        d="
M986.432739,962.429199 
 C1026.653809,928.345398 1072.717407,910.734985 1124.496704,910.539368 
 C1233.327881,910.128235 1315.639526,986.307495 1335.997437,1078.188110 
 C1362.580566,1198.163818 1285.973389,1315.032593 1165.098755,1337.128296 
 C1043.205933,1359.410034 937.074890,1277.378906 916.075928,1169.411011 
 C900.223511,1087.904663 924.553528,1018.864929 986.432739,962.429199 
z"
      />
    </svg>
  )
}

/* eslint-disable @typescript-eslint/no-explicit-any */
import { useAuth } from '@clerk/clerk-react'
import { ReactElement, useEffect, useState } from 'react'
import { inviteCodeAtom } from '../../store/code.store'
import { useAtom } from 'jotai'
import { eventAtom } from '../../store/eventSession.store'
import SongSearch from './../../components/SongSearch'
import EventDetails from './../../components/EventDetails'
import AddTipDialog from './AddTipDialog'
import { useAbortableFetch } from '../../hooks/abortableFetch.hooks'
import { Event } from '../../../shared/interfaces/Event'

type SERVICES =
  | 'Spotify'
  | 'Apple Music'
  | 'Youtube Music'
  | 'SoundCloud'
  | 'None'

interface SpotifySearchResponse {
  song: {
    name: string
    artists: string[]
    // Required by Spotify
    spotifyURL: string
  }
  album: {
    name: string
    albumArt: {
      height: number
      width: number
      url: string
    }
  }
}

function UserRequestPage(): ReactElement {
  const { getToken } = useAuth()
  const [inviteCode, setInviteCode] = useAtom(inviteCodeAtom)
  const [event, setEvent] = useAtom(eventAtom)
  const [currentService, updateCurrentService] = useState<SERVICES>('Spotify')

  // Create Event Dialog
  const [requestDialogOpen, setRequestDialogOpen] = useState(false)
  const [selectedSong, setSelectedSong] =
    useState<SpotifySearchResponse | null>(null)

  const [tip, setTip] = useState<number>(0)
  const [step, setStep] = useState<number>(0)

  const leaveEvent = () => {
    setInviteCode(null)
    setEvent(null)
  }

  const loadEvent = () => {
    getToken().then((token) => {
      fetch(`/api/event?code=${inviteCode}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      }).then(async (response) => {
        const resp: Event = await response.json()
        setEvent(resp)
      })
    })
  }

  useEffect(() => {
    loadEvent()
  }, [inviteCode])

  const closeAndReset = () => {
    setRequestDialogOpen(false)
    setStep(0)
    setSelectedSong(null)
    setTip(0)
  }

  const { triggerFn: submitSongFn } = useAbortableFetch({
    type: 'POST',
    url: '/api/event/request',
    authed: true,
    requestSuccessful() {
      closeAndReset()
    }
  })

  const submitSong = (data: any) => submitSongFn({ body: data })

  const handleSongSelect = (song: SpotifySearchResponse) => {
    setRequestDialogOpen(true)
    setSelectedSong(song)
  }

  return (
    <div className="flex flex-col w-full h-full px-2 space-x-1 mt-2 pb-24">
      <AddTipDialog
        isOpen={requestDialogOpen}
        dialogClose={() => closeAndReset()}
        songSubmit={submitSong}
        selectedSong={selectedSong}
        event={event}
        currentService={currentService}
        tip={tip}
        updateTip={setTip}
        step={step}
        setStepper={setStep}
      />
      {!event ? (
        <></>
      ) : (
        <EventDetails onLeave={() => leaveEvent()} event={event} />
      )}
      <SongSearch onSongSelect={handleSongSelect} requests={[]} reset />
    </div>
  )
}

export default UserRequestPage

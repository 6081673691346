import { ReactElement, useState, useEffect } from 'react'
import { Event } from './../../shared/interfaces/Event'
import DateRangeIcon from '@mui/icons-material/DateRange'
import PlaceIcon from '@mui/icons-material/Place'
import { useUser } from '../hooks/user.hooks'

const formatDate = (dateStr: string) => {
  const date = new Date(dateStr)
  const formatter = new Intl.DateTimeFormat('en', {
    month: 'short',
    day: 'numeric'
  })
  const toReturn = formatter.format(date)
  return toReturn
}

const formatTime = (str: string) => {
  const date = new Date(str)
  const formatter = new Intl.DateTimeFormat('en', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true
  })
  const toReturn = formatter.format(date)
  return toReturn
}

function EventsList({
  items,
  onSelect
}: {
  items: Event[]
  onSelect: (code: string) => void
}) {
  const { user } = useUser()
  const Events = ({ selected }: { selected: (code: string) => void }) => (
    <>
      {items.map((item, index) => {
        return (
          <div key={item.id} className="p-4 w-full md:w-1/2 lg:w-1/3">
            <div className="h-full border-2 border-gray-800 rounded-lg overflow-hidden">
              {/* <img
                className="lg:h-48 md:h-36 w-full object-cover object-center"
                src={`https://picsum.photos/id/${index}/720/400`}
                alt="blog"
              /> */}
              <div className="p-6">
                <h1 className="text-left title-font text-lg md:text-2xl font-medium text-white mb-2">
                  {item.name}
                </h1>
                <p className="leading-relaxed text-lg text-left mb-2">
                  with {item.dj.name}
                </p>
                <h2 className="tracking-widest text-xs title-font font-medium text-gray-500 mb-1">
                  <span className="text-gray-400 inline-flex items-center py-1 leading-none text-sm w-full justify-start">
                    <DateRangeIcon className="mr-2" />
                    {' ' + formatDate(item?.date as string)} –{' '}
                    {formatTime(item?.date as string)}
                  </span>
                </h2>
                <h2 className="tracking-widest text-xs title-font font-medium text-gray-500 mb-2">
                  <span className="text-gray-400 inline-flex items-center py-1 leading-none text-sm w-full justify-start">
                    <PlaceIcon className="mr-2" />
                    {' ' + item?.address}
                  </span>
                </h2>
                {/* <p className="leading-relaxed mb-3">
                <span className="text-gray-400 inline-flex items-center py-1 pl-2 leading-none text-sm w-1/2">
                  <PlaceIcon className="mr-2" />
                  {' ' + item?.address}
                </span>
              </p> */}
                <div className="flex items-center flex-wrap ">
                  <a
                    onClick={() => selected(item.code)}
                    className="text-blue-400 inline-flex items-center cursor-pointer md:mb-2 lg:mb-0"
                  >
                    {user?.role === 'dj' ? 'Activate' : 'Join'} Event
                    <svg
                      className="w-4 h-4 ml-2"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth="2"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path d="M5 12h14"></path>
                      <path d="M12 5l7 7-7 7"></path>
                    </svg>
                  </a>
                  {/* <span className="text-gray-500 mr-3 inline-flex items-center lg:ml-auto md:ml-0 ml-auto leading-none text-sm pr-3 py-1 border-r-2 border-gray-800">
                    <svg
                      className="w-4 h-4 mr-1"
                      stroke="currentColor"
                      strokeWidth="2"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      viewBox="0 0 24 24"
                    >
                      <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                      <circle cx="12" cy="12" r="3"></circle>
                    </svg>
                    1.2K
                  </span>
                  <span className="text-gray-500 inline-flex items-center leading-none text-sm">
                    <svg
                      className="w-4 h-4 mr-1"
                      stroke="currentColor"
                      strokeWidth="2"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      viewBox="0 0 24 24"
                    >
                      <path d="M21 11.5a8.38 8.38 0 01-.9 3.8 8.5 8.5 0 01-7.6 4.7 8.38 8.38 0 01-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 01-.9-3.8 8.5 8.5 0 014.7-7.6 8.38 8.38 0 013.8-.9h.5a8.48 8.48 0 018 8v.5z"></path>
                    </svg>
                    6
                  </span> */}
                </div>
              </div>
            </div>
          </div>
        )
      })}
    </>
  )
  return (
    <section className="text-gray-400 bg-gray-900 body-font">
      <div className="container px-5 py-6 mx-auto">
        <div className="flex flex-wrap -m-4">
          <Events selected={onSelect as any} />
        </div>
      </div>
    </section>
  )
}

export default EventsList

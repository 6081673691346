import { ReactElement } from 'react'
import ProfileCard from './ProfileCard'
import BillingCard from './BillingCard'
import { Button } from '@mui/material'
import { useUser } from '../../hooks/user.hooks'
import DJCard from './DJCard'
import RoleGaurd from '../../components/RoleGaurd'
function SettingsPage(): ReactElement {
  const { deleteUser, user } = useUser()

  return (
    <div className="flex flex-col space-y-3 p-5 w-full">
      <ProfileCard />
      <RoleGaurd allow={['dj']}>
        <DJCard />
      </RoleGaurd>
      <BillingCard />
      <div className="flex flex-row justify-center items-center">
        <Button variant="outlined" color="warning" onClick={deleteUser}>
          Delete User
        </Button>
      </div>
    </div>
  )
}

export default SettingsPage

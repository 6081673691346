/* eslint-disable @typescript-eslint/no-explicit-any */
import { useAuth } from '@clerk/clerk-react'
import {
  ReactElement,
  useEffect,
  useState,
  forwardRef,
  SyntheticEvent
} from 'react'
import { inviteCodeAtom } from '../../store/code.store'
import { useAtom } from 'jotai'
import { eventAtom } from '../../store/eventSession.store'
import SongSearch from './../../components/SongSearch'
import EventDetails from './../../components/EventDetails'
import AddTipDialog from './AddTipDialog'
import { useAbortableFetch } from '../../hooks/abortableFetch.hooks'
import { Event as _Event } from '../../../shared/interfaces/Event'
import Snackbar, { SnackbarCloseReason } from '@mui/material/Snackbar'
import MuiAlert, { AlertColor, AlertProps } from '@mui/material/Alert'

const Alert = forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
})

type SERVICES =
  | 'Spotify'
  | 'Apple Music'
  | 'Youtube Music'
  | 'SoundCloud'
  | 'None'

interface SpotifySearchResponse {
  song: {
    name: string
    artists: string[]
    // Required by Spotify
    spotifyURL: string
  }
  album: {
    name: string
    albumArt: {
      height: number
      width: number
      url: string
    }
  }
}

function UserEventView({ code }: { code: string }): ReactElement {
  const { getToken } = useAuth()
  const [inviteCode, setInviteCode] = useAtom(inviteCodeAtom)
  const [eventA, setEventAtom] = useAtom(eventAtom)
  const [event, setEvent] = useState<_Event | null>(null)
  const [currentService, updateCurrentService] = useState<SERVICES>('Spotify')
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [snackbarStatus, setSnackbarStatus] = useState<AlertColor>('success')
  const [requests, setRequests] = useState<any[]>([])
  const [resetSearch, toggleResetSearch] = useState(false)

  useEffect(() => {
    // Set up the interval when the component mounts
    const interval = setInterval(getSongRequests, 3000)
    getSongRequests()
    // Clean up the interval when the component unmounts to avoid memory leaks
    return () => clearInterval(interval)
  }, [event]) // The empty dependency array ensures the effect runs only once on mount

  const handleClose = (
    event?: Event | SyntheticEvent<any, Event>,
    reason?: SnackbarCloseReason
  ) => {
    if (reason === 'clickaway') {
      return
    }
    setSnackbarOpen(false)
  }

  // Create Event Dialog
  const [requestDialogOpen, setRequestDialogOpen] = useState(false)
  const [selectedSong, setSelectedSong] =
    useState<SpotifySearchResponse | null>(null)

  const [tip, setTip] = useState<number>(0)
  const [step, setStep] = useState<number>(0)

  const getSongRequests = () => {
    if (event?.id) {
      getToken().then((token) => {
        fetch(`/api/event/request?event=${event?.id}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
          }
        })
          .then((response) => response.json())
          .then((resp) => {
            setRequests(resp.requests)
          })
      })
    }
  }

  const leaveEvent = () => {
    setInviteCode(null)
    setEvent(null)
  }

  const loadEvent = () => {
    getToken().then((token) => {
      fetch(`/api/event?code=${code}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      }).then(async (response) => {
        const resp: _Event = await response.json()
        setEvent(resp)
        setEventAtom(resp)
      })
    })
  }

  useEffect(() => {
    loadEvent()
  }, [])

  const closeAndReset = () => {
    setRequestDialogOpen(false)
    setStep(0)
    setSelectedSong(null)
    setTip(0)
  }

  const handleSubmit = (status: AlertColor) => {
    closeAndReset()
    setSnackbarStatus(status)
    setSnackbarOpen(true)
  }

  const { triggerFn: submitSongFn } = useAbortableFetch({
    type: 'POST',
    url: '/api/event/request',
    authed: true,
    requestFailed() {
      handleSubmit('error')
    },
    requestSuccessful() {
      handleSubmit('success')
    }
  })

  const submitSong = (data: any) => {
    submitSongFn({ body: data })
    // TODO: HACKY
    if (!resetSearch) {
      toggleResetSearch(true)
    }
    setTimeout(() => {
      toggleResetSearch(false)
    }, 5000)
  }

  const handleSongSelect = (song: SpotifySearchResponse) => {
    setRequestDialogOpen(true)
    loadEvent()
    setSelectedSong(song)
  }

  return (
    <div className="flex flex-col flex-grow w-full h-full px-2 space-x-1 mt-2 pb-4">
      {event && (
        <AddTipDialog
          isOpen={requestDialogOpen}
          dialogClose={() => closeAndReset()}
          songSubmit={submitSong}
          selectedSong={selectedSong}
          event={event}
          currentService={currentService}
          tip={tip}
          updateTip={setTip}
          step={step}
          setStepper={setStep}
        />
      )}
      {!event ? (
        <></>
      ) : (
        <EventDetails onLeave={() => leaveEvent()} event={event} />
      )}
      <SongSearch
        reset={resetSearch}
        requests={requests}
        onSongSelect={handleSongSelect}
      />
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={5000}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={snackbarStatus}
          sx={{ width: '100%' }}
        >
          {snackbarStatus === 'success' &&
            'Your song request was successfully submitted!'}
          {snackbarStatus === 'error' &&
            'Error: could not process song request'}
        </Alert>
      </Snackbar>
    </div>
  )
}

export default UserEventView

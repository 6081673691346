import { useUser as UserClerkUser } from '@clerk/clerk-react'
import {
  Card,
  CardContent,
  Typography,
  CardActions,
  Button,
  TextField,
  Avatar,
  CircularProgress
} from '@mui/material'
import { ReactElement, useEffect, useState } from 'react'
import { User } from '../../../shared/interfaces/User'
import { useUser } from '../../hooks/user.hooks'
import { stringAvatar } from '../../utils/avatar.utils'
import styled from '@emotion/styled'
import tw from 'twin.macro'
import { UserProfile, UserButton } from '@clerk/clerk-react'

const BASE_URL = import.meta.env.REACT_API_URL

const StyledButton = styled.button<{ primary: boolean }>`
  ${tw`px-4 py-2 bg-blue-500 text-white rounded-md shadow-sm`}
  ${(props) =>
    props.primary ? tw`bg-blue-500 text-white` : tw`bg-gray-500 text-white`}
`

function ProfileCard(): ReactElement {
  const { user, updateUser } = useUser()
  const { user: clerkUser } = UserClerkUser()
  const [userForm, setUserForm] = useState<{
    firstName: string
    lastName: string
    email: string
    phoneNumber: string
  }>({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: ''
  })
  const [isFinished, finished] = useState<boolean>(false)

  useEffect(() => {
    finished(true)
    if (user)
      setUserForm({
        email: user.email,
        firstName: user.firstName,
        lastName: user.lastName,
        phoneNumber: user.phoneNumber
      })
  }, [user])

  const updateUserFn = () => {
    finished(false)
    updateUser({
      ...(user as User),
      email: userForm.email,
      phoneNumber: userForm.phoneNumber,
      firstName: userForm.firstName,
      lastName: userForm.lastName
    })
  }

  return (
    <Card sx={{ minWidth: 275 }}>
      <CardContent>
        <div className="flex flex-row justify-between items-center">
          <div className="flex flex-col justify-center items-start -mt-5">
            <Typography
              sx={{ fontSize: 14 }}
              color="text.secondary"
              gutterBottom
            >
              Profile
            </Typography>
            <Typography variant="h5" component="div">
              {user?.firstName} {user?.lastName}
            </Typography>
          </div>
          <UserButton
            appearance={{
              elements: {
                userButtonTrigger: {
                  width: '5rem',
                  height: '5rem'
                }
              }
            }}
          />
          {/* <Avatar
            style={{
              width: '5rem',
              height: '5rem',
              color: 'gray'
            }}
            {...stringAvatar(`${user?.firstName} ${user?.lastName}`)}
            imgProps={
              clerkUser?.profileImageUrl
                ? {
                    src: clerkUser?.profileImageUrl
                  }
                : {}
            }
          /> */}
          {/* <div classNam e="w-24 h-24 sm:w-32 sm:h-32 rounded-full bg-blue-600 shadow-md"></div> */}
        </div>
        <Typography sx={{ mb: 1.5, mt: -2.75 }} color="text.secondary">
          @{user?.firstName}
          {user?.lastName}
        </Typography>

        <div className="w-full lg:px-24 lg:space-y-12 space-y-4 md:space-y-0 pt-6">
          {!isFinished ? (
            <div className="flex flex-row justify-center items-center">
              <CircularProgress />
            </div>
          ) : (
            <>
              <div className="flex flex-col md:flex-row md:justify-around md:items-center w-full lg:space-x-12 space-y-4 md:space-y-0">
                <TextField
                  id="outlined-basic"
                  label="First Name"
                  variant="outlined"
                  fullWidth
                  value={userForm.firstName}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setUserForm({
                      ...userForm,
                      firstName: event.target.value
                    })
                  }}
                />
                <TextField
                  id="outlined-basic"
                  label="Last Name"
                  variant="outlined"
                  fullWidth
                  value={userForm.lastName}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setUserForm({
                      ...userForm,
                      lastName: event.target.value
                    })
                  }}
                />
              </div>
              <div className="flex flex-col md:flex-row md:justify-around md:items-center w-full lg:space-x-12 space-y-4 md:space-y-0">
                <TextField
                  id="outlined-basic"
                  label="Email"
                  variant="outlined"
                  fullWidth
                  value={userForm.email}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setUserForm({
                      ...userForm,
                      email: event.target.value
                    })
                  }}
                />
                <TextField
                  id="outlined-basic"
                  label="Phone Number"
                  variant="outlined"
                  fullWidth
                  value={userForm.phoneNumber}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setUserForm({
                      ...userForm,
                      phoneNumber: event.target.value
                    })
                  }}
                />
              </div>
            </>
          )}
        </div>
      </CardContent>
      <CardActions>
        <div className="w-full flex flex-row justify-center lg:justify-end p-4 lg:pr-32 items-center">
          <Button
            size="medium"
            variant="contained"
            onClick={updateUserFn}
            disabled={!user}
          >
            Update Profile
          </Button>
        </div>
      </CardActions>
    </Card>
  )
}

export default ProfileCard

import { ReactElement, useState } from 'react'
import { Event } from '../../shared/interfaces/Event'
import { Button, CircularProgress } from '@mui/material'
import DateRangeIcon from '@mui/icons-material/DateRange'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import PlaceIcon from '@mui/icons-material/Place'
import Switch from '@mui/material/Switch'
import Collapse from '@mui/material/Collapse'
import FormControlLabel from '@mui/material/FormControlLabel'
import UpdateEventMinimumDialog from './UpdateEventMinimum'
import { Link, BrowserRouter as Router, Route } from 'react-router-dom'

const formatDate = (dateStr: string) => {
  const date = new Date(dateStr)
  const formatter = new Intl.DateTimeFormat('en', {
    month: 'short',
    day: 'numeric'
  })
  const toReturn = formatter.format(date)
  return toReturn
}

const formatTime = (str: string) => {
  const date = new Date(str)
  const formatter = new Intl.DateTimeFormat('en', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true
  })
  const toReturn = formatter.format(date)
  return toReturn
}

function EventDetails({
  event,
  isDJ = false,
  onLeave
}: {
  event: Event
  isDJ?: boolean
  onLeave: () => void
}): ReactElement {
  const [checked, setChecked] = useState(true)
  const handleChange = () => {
    setChecked((prev) => !prev)
  }

  const ToggleDetails = () => {
    return (
      <FormControlLabel
        control={<Switch checked={checked} onChange={handleChange} />}
        label={(!checked ? 'Show' : 'Hide') + ' Details'}
      />
    )
  }
  const LeaveButton = () => {
    if (event === null) return null
    return (
      <Button variant="outlined" color="warning" onClick={() => onLeave()}>
        Leave Event
      </Button>
    )
  }

  const Topbar = () => {
    return (
      <div className="w-full flex justify-between p-4 text-white">
        <LeaveButton />
        <ToggleDetails />
      </div>
    )
  }

  return (
    <div className="flex flex-wrap -m-2">
      {event ? (
        <div className="p-2 h-full w-full">
          <Topbar />
          <div
            className={
              'h-full flex flex-col items-center border-b-2 border-gray-700 mb-4 px-4 pt-2' +
              (checked ? ' py-4' : ' py-0')
            }
          >
            <Collapse classes={{ root: 'w-full' }} in={checked}>
              <div className="flex flex-row flex-wrap">
                <div className="flex-grow flex flex-col items-start w-full mb-4">
                  <h2 className="text-white title-font text-2xl">
                    {event?.name}
                  </h2>
                  <p className="text-gray-400 text-lg md:text-xl">
                    featuring{' '}
                    <Link to={`/dj-profile/${event?.dj?.id}`}>
                      <b className="hover:cursor-pointer underline">
                        {event?.dj?.name}{' '}
                        {/* {event?.dj?.social
                          ? '(@' + event?.dj?.social + ')'
                          : ''} */}
                      </b>
                    </Link>
                  </p>

                  {/* <p className="text-gray-400 text-xl">Event code: {event?.code}</p> */}
                  {/* <div className="text-center mt-2 leading-none flex justify-center w-full py-4">
                  <p className="text-gray-400 inline-flex items-center py-1 pr-2 leading-none text-xl w-1/2">
                    <HeadphonesIcon className="mr-2" />
                    {event?.dj?.name}
                  </p>
                  <p className="text-gray-400 inline-flex items-center py-1 pl-2 leading-none text-xl w-1/2">
                    <AlternateEmailIcon className="mr-2" />
                    {' ' + event?.dj?.social}
                  </p>
                </div> */}
                </div>
                {isDJ && (
                  <div className="w-full mb-4 flex flex-row justify-between items-center">
                    <span className="text-gray-400 inline-flex items-center py-1 pr-2 leading-none text-sm md:text-base w-1/2">
                      <AttachMoneyIcon />
                      <span className="font-medium text-lg title-font mr-2">
                        {' '}
                        {event.minimumTip ?? 0}
                      </span>
                      Min. Tip{' '}
                      {event.restrictToFree && (
                        <span className="ml-1">(Free Event)</span>
                      )}
                    </span>
                    {!event.restrictToFree && (
                      <UpdateEventMinimumDialog
                        minimumTip={event.minimumTip ?? 0}
                        eventID={event.id}
                      />
                    )}
                  </div>
                )}
              </div>
              <div className="text-center leading-none flex justify-center w-full">
                <span className="text-gray-400 inline-flex items-center py-1 pr-2 border-r-2 border-gray-700 border-opacity-50 leading-none text-sm md:text-base w-1/2">
                  <DateRangeIcon className="mr-2" />
                  {' ' + formatDate(event?.date as string)} –{' '}
                  {formatTime(event?.date as string)}
                </span>
                <span className="text-gray-400 inline-flex items-center py-1 pl-2 leading-none text-sm md:text-base w-1/2">
                  <PlaceIcon className="mr-2" />
                  {/* <AlternateEmailIcon /> */}
                  {' ' + event?.address}
                </span>
              </div>
            </Collapse>
          </div>
        </div>
      ) : (
        <CircularProgress />
      )}
    </div>
  )
}

export default EventDetails

import { ReactElement, useEffect, useState } from 'react'
import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
  InputAdornment,
  Button,
  TextField,
  CircularProgress
} from '@mui/material'
import AddCash from './../AddCash'
import type { PaymentMethod } from '@stripe/stripe-js'
import CreditCard from '../../components/CreditCard'
import { useAbortableFetch } from '../../hooks/abortableFetch.hooks'

interface CardsResponse {
  object: 'list'
  data: PaymentMethod[]
}

const PageTitles = ['Request Song', 'Enter Payment Information']

type SERVICES =
  | 'Spotify'
  | 'Apple Music'
  | 'Youtube Music'
  | 'SoundCloud'
  | 'None'

interface SongRequestForm {
  event: string
  name: string
  source: SERVICES
  sourceId: string
  artist: any[]
  tip: number
  albumArt: string
  paymentMethod?: string
}

function AddTipDialog({
  isOpen,
  dialogClose,
  songSubmit,
  selectedSong,
  event,
  currentService,
  tip,
  updateTip,
  step,
  setStepper
}: {
  isOpen: boolean
  dialogClose: () => void
  songSubmit: (body: any) => void
  selectedSong: any | null
  event: any
  currentService: SERVICES
  tip: number
  updateTip: (num: number) => void
  step: number
  setStepper: (num: number) => void
}): ReactElement {
  const [cards, setCards] = useState<PaymentMethod[]>([])

  const [selectedCard, setSelectedCard] = useState(0)

  const createFormData = (paymentMethod?: string) => {
    const form: SongRequestForm = {
      event: event?.id,
      name: selectedSong?.song.name,
      source: currentService,
      sourceId: selectedSong?.song.spotifyURL,
      artist: selectedSong?.song.artists,
      albumArt: selectedSong?.album.albumArt.url,
      tip
    }
    if (paymentMethod || cards.length > 0) {
      form.paymentMethod = paymentMethod
        ? paymentMethod
        : cards[selectedCard].id
    }
    return form
  }

  const Cards = ({ data }: { data: PaymentMethod[] }) => (
    <div className="bg-white mx-auto flex flex-wrap justify-center items-center p-0 lg:p-2 container">
      {data.map((card, index) => {
        return (
          <div className="w-full p-4 md:w-1/2 lg:w-1/3">
            <CreditCard
              info={card}
              selected={index === selectedCard}
              onClick={() => {
                if (index === selectedCard) setSelectedCard(-1)
                else setSelectedCard(index)
              }}
              size="sm"
            />
          </div>
        )
      })}
    </div>
  )

  const { isLoading: isCardListLoading, triggerFn: loadCards } =
    useAbortableFetch<CardsResponse>({
      type: 'GET',
      url: '/api/billing/cards',
      authed: true,
      requestSuccessful({ data }) {
        setCards(data.data)
      }
    })

  useEffect(() => {
    loadCards()
  }, [])

  const Step1 = () => {
    return (
      <DialogContent>
        <DialogContentText>
          <div className="flex flex-row justify-center items-center mb-4">
            <span className="text-sm text-gray-500 text-center">
              Request "{selectedSong?.song.name}" for the current event "
              {event?.name}"?
            </span>
          </div>
        </DialogContentText>
        {!event.restrictToFree && (
          <TextField
            autoFocus
            margin="dense"
            id="tip"
            label={
              event?.minimumTip && event?.minimumTip > 0
                ? 'DJ Tip'
                : 'DJ Tip (optional)'
            }
            fullWidth
            variant="standard"
            value={tip}
            onChange={(_event: React.ChangeEvent<HTMLInputElement>) => {
              const value = Number(_event.target.value)
              if (!Number.isNaN(value)) updateTip(value)
            }}
            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              )
            }}
          />
        )}
        {event?.minimumTip && event?.minimumTip > 0 ? (
          <div className="flex flex-row justify-center items-center mt-6">
            <span className="text-sm text-gray-500 text-center">
              DJ has requested a minimum tip of ${event.minimumTip} to request a
              song
            </span>
          </div>
        ) : (
          ''
        )}
      </DialogContent>
    )
  }

  const Step2 = () => {
    if (cards.length === 0) setStepper(2)
    return (
      <DialogContent>
        <DialogContentText align="center">
          Choose a payment method
        </DialogContentText>
        <p className="flex flex-row w-full justify-center items-center pb-4"></p>
        {isCardListLoading ? (
          <CircularProgress />
        ) : (
          <div className="flex flex-col w-full justify-end items-center">
            <Cards data={cards as PaymentMethod[]} />
            <Button
              onClick={() => {
                setStepper(2)
                setSelectedCard(-1)
              }}
            >
              Add New Payment Method
            </Button>
          </div>
        )}
      </DialogContent>
    )
  }

  const Step3 = () => {
    return (
      <DialogContent>
        <DialogContentText align="center">
          Tip {'$' + tip + '.00'} to the DJ
          <br />
          (Payment is only processed once DJ has accepted song request)
        </DialogContentText>
        <p className="flex flex-row w-full justify-center items-center pb-4"></p>
        <AddCash
          djID={event?.dj?.id}
          onFormSubmit={(paymentMethod: string) => {
            songSubmit(createFormData(paymentMethod))
            loadCards()
          }}
          amount={tip * 100}
        ></AddCash>
      </DialogContent>
    )
  }

  return (
    <Dialog
      open={isOpen}
      onClose={() => dialogClose()}
      fullWidth={true}
      maxWidth={'sm'}
    >
      <DialogTitle align="center">{PageTitles[step]}</DialogTitle>
      {step === 0 ? <Step1 /> : step === 1 ? <Step2 /> : <Step3 />}
      <DialogActions className="flex justify-between">
        {step === 1 && <Button onClick={() => setStepper(0)}>Prev</Button>}
        {step === 2 && (
          <Button onClick={() => (tip === 0 ? setStepper(0) : setStepper(1))}>
            Prev
          </Button>
        )}
        <Button onClick={() => dialogClose()}>Cancel</Button>
        {step === 0 && (
          <Button
            disabled={tip < (event?.minimumTip ? event?.minimumTip : 0)}
            onClick={() => {
              if (tip > 0) setStepper(1)
              else songSubmit(createFormData())
            }}
          >
            {event?.minimumTip === 0 ? 'Submit' : 'Next'}
          </Button>
        )}
        {step === 1 && (
          <Button
            onClick={() => songSubmit(createFormData())}
            disabled={selectedCard === -1}
          >
            Submit
          </Button>
        )}
      </DialogActions>
    </Dialog>
  )
}
export default AddTipDialog

/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react'
import { useAuth } from '@clerk/clerk-react'
import { useUser } from '../../hooks/user.hooks'
import { ReactElement, useEffect, useState } from 'react'
import { inviteCodeAtom } from '../../store/code.store'
import { useAtom } from 'jotai'
import { eventAtom } from '../../store/eventSession.store'
import { Event } from '../../../shared/interfaces/Event'
import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'
import IconButton from '@mui/material/IconButton'
import Stack from '@mui/material/Stack'
import EventDetails from '../../components/EventDetails'
import ChecklistIcon from '@mui/icons-material/Checklist'
import LibraryMusicIcon from '@mui/icons-material/LibraryMusic'
import Tabs from '@mui/material/Tabs'
import Badge from '@mui/material/Badge'
import Tab from '@mui/material/Tab'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Checkbox from '@mui/material/Checkbox'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import { useSnackbar } from 'notistack'

const darkTheme = createTheme({
  palette: {
    mode: 'dark'
  }
})

function DJEventView(): ReactElement {
  const { getToken } = useAuth()
  const { user } = useUser()
  const [inviteCode, setInviteCode] = useAtom(inviteCodeAtom)
  const [event, setEvent] = useAtom(eventAtom)
  const [requests, setRequests] = useState<any[]>([])
  const [acceptedSongs, setAcceptedSongs] = useState<any[]>([])
  const [selectedTab, setSelectedTab] = useState(0)

  const changeTabs = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue)
  }
  const [checked, setChecked] = useState<number[] | any[]>([])

  const handleToggle = (value: number) => () => {
    const currentIndex = checked.indexOf(value)
    const newChecked = [...checked]

    if (currentIndex === -1) {
      newChecked.push(value)
    } else {
      newChecked.splice(currentIndex, 1)
    }

    setChecked(newChecked)
  }

  const { enqueueSnackbar } = useSnackbar()

  const openSnackbar = (key: string, message: string) =>
    enqueueSnackbar({
      key,
      anchorOrigin: {
        horizontal: 'left',
        vertical: 'bottom'
      },
      TransitionProps: {
        direction: 'up'
      },
      message,
      preventDuplicate: true,
      persist: false,
      autoHideDuration: 2_500
    })

  useEffect(() => {
    // Set up the interval when the component mounts
    const interval = setInterval(getSongRequests, 3000)
    getSongRequests()
    // Clean up the interval when the component unmounts to avoid memory leaks
    return () => clearInterval(interval)
  }, [event]) // The empty dependency array ensures the effect runs only once on mount

  useEffect(() => {
    // Set up the interval when the component mounts
    const interval = setInterval(getAcceptedRequests, 3000)
    getAcceptedRequests()
    // Clean up the interval when the component unmounts to avoid memory leaks
    return () => clearInterval(interval)
  }, [event]) // The empty dependency array ensures the effect runs only once on mount

  const loadEvent = () => {
    getToken().then((token) => {
      fetch(`/api/event?code=${inviteCode}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      }).then(async (response) => {
        const resp: Event = await response.json()
        setEvent(resp)
      })
    })
  }

  useEffect(() => {
    loadEvent()
  }, [inviteCode])

  const leaveEvent = () => {
    setInviteCode(null)
    setEvent(null)
  }

  const getSongRequests = () => {
    if (event?.id) {
      getToken().then((token) => {
        fetch(`/api/event/request?event=${event?.id}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
          }
        })
          .then((response) => response.json())
          .then((resp) => {
            setRequests(resp.requests)
          })
      })
    }
  }

  const getAcceptedRequests = () => {
    if (event?.id) {
      getToken().then((token) => {
        fetch(`/api/event/request?event=${event?.id}&status=accepted`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
          }
        })
          .then((response) => response.json())
          .then((resp) => {
            setAcceptedSongs(resp.requests)
          })
      })
    }
  }

  const reactToSong = (id: string, status: string) => {
    getToken().then((token) => {
      fetch(`/api/event/request/reaction`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({
          reaction: status,
          request: id
        })
      })
        .then(() => {
          openSnackbar('request.reaction', 'Payment Successful')
          getSongRequests()
        })
        .catch(() => {
          openSnackbar('request.reaction', 'Payment Failure')
        })
    })
  }

  const NoRequestsFound = ({ tab }: { tab: 'requests' | 'queued' }) => (
    <div className="flex flex-col flex-wrap flex-grow">
      <div className="text-center flex flex-col justify-center flex-grow h-full w-full">
        <h1 className="title-font sm:text-4xl text-2xl mb-4 font-medium text-white">
          No {tab === 'requests' ? 'pending' : 'accepted'} song requests found.
        </h1>
        <p className="text-gray-400 mb-8 leading-relaxed">
          You do not currently have any{' '}
          <b>{tab === 'requests' ? 'unprocessed' : 'unplayed'}</b> song
          requests.
          <br />
          (This page refreshes every 3 seconds.)
        </p>
      </div>
    </div>
  )

  const AcceptedSongs = ({ items }: { items: any[] }) => (
    <>
      <ThemeProvider theme={darkTheme}>
        <div className="w-full flex justify-center container mx-auto pt-8">
          <List
            sx={{
              width: '100%',
              maxWidth: 360,
              color: 'white',
              bgcolor: '#1e293b',
              borderRadius: '0.25rem'
            }}
          >
            {items.map((item, index) => {
              const labelId = `checkbox-list-label-${index}`
              return (
                <>
                  <ListItem
                    key={item.id}
                    secondaryAction={
                      <>
                        {checked.indexOf(index) !== -1 ? (
                          <Button
                            onClick={() =>
                              reactToSong(item.id as string, 'played')
                            }
                            variant="contained"
                            color="success"
                          >
                            Confirm
                          </Button>
                        ) : (
                          <div className="flex flex-col w-full">
                            <span className="title-font font-medium text-lg text-green-500">
                              + ${item.tip}.00
                            </span>
                          </div>
                        )}
                      </>
                    }
                    disablePadding
                  >
                    <ListItemButton
                      role={undefined}
                      onClick={handleToggle(index)}
                      dense
                    >
                      <ListItemIcon>
                        <Checkbox
                          color="success"
                          edge="start"
                          checked={checked.indexOf(index) !== -1}
                          tabIndex={-1}
                          disableRipple
                          inputProps={{ 'aria-labelledby': labelId }}
                        />
                      </ListItemIcon>
                      {checked.indexOf(index) !== -1 ? (
                        <ListItemText
                          primary={
                            <>
                              <h2 className="title-font font-medium text-base text-white">
                                Mark song as played?
                              </h2>
                            </>
                          }
                          secondary={
                            <h3 className="text-gray-500 text-base">
                              Please confirm.
                            </h3>
                          }
                        />
                      ) : (
                        <ListItemText
                          primary={
                            <>
                              <h2 className="title-font font-medium text-lg text-white">
                                {item.song.name}
                              </h2>
                            </>
                          }
                          secondary={
                            <h3 className="text-gray-500 text-base">
                              {' '}
                              {item.song.artist.join(', ')}
                            </h3>
                          }
                        />
                      )}
                    </ListItemButton>
                  </ListItem>
                  {index < items.length - 1 && <Divider />}
                </>
              )
            })}
          </List>
        </div>
      </ThemeProvider>
    </>
  )

  const RequestList = ({ items }: { items: any[] }) => (
    <>
      <div className="flex flex-col flex-wrap">
        {items.map((item, index) => {
          return (
            <div key={item.id} className="p-2 w-full">
              <div className="h-full flex justify-between items-center border-gray-800 border p-4 rounded-lg">
                <div className="flex-grow">
                  <h2 className="text-white title-font font-medium">
                    {item.song?.name}
                  </h2>
                  <p className="text-gray-600">
                    {(item.song?.artist ?? []).join(',')}
                  </p>
                </div>
                <div className="flex flex-col">
                  <span className="title-font font-medium text-2xl text-white">
                    ${item.tip}.00
                  </span>
                  {/* <p className="text-gray-600">{item.user?.firstName} {item.user?.lastName}</p> */}
                  <Stack direction="row">
                    <IconButton
                      aria-label="Accept"
                      color="primary"
                      onClick={() => reactToSong(item.id as string, 'accepted')}
                    >
                      <CheckIcon />
                    </IconButton>
                    <IconButton
                      aria-label="Reject"
                      color="secondary"
                      onClick={() => reactToSong(item.id as string, 'denied')}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Stack>
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </>
  )

  const TabData = () => (
    <ThemeProvider theme={darkTheme}>
      <Tabs value={selectedTab} onChange={changeTabs} centered>
        <Tab
          label={
            <div className="flex items-center justify-between">
              <span className="pr-2">Song Requests</span>
              <Badge badgeContent={requests.length} color="success">
                <LibraryMusicIcon />
              </Badge>
            </div>
          }
        />
        <Tab
          label={
            <div className="flex items-center justify-between">
              <span className="pr-2">Queued Songs</span>
              <Badge badgeContent={acceptedSongs.length} color="success">
                <ChecklistIcon />
              </Badge>
            </div>
          }
        />
      </Tabs>
    </ThemeProvider>
  )

  const RequestsTab = () => (
    <>
      {requests.length > 0 ? (
        <RequestList items={requests as any} />
      ) : (
        <NoRequestsFound tab={'requests'} />
      )}
    </>
  )

  const QueuedSongsTab = () => (
    <>
      {acceptedSongs.length > 0 ? (
        <AcceptedSongs items={acceptedSongs} />
      ) : (
        <NoRequestsFound tab={'queued'} />
      )}
    </>
  )

  return (
    <div className="flex flex-col flex-grow w-full h-full px-2 mt-2 pb-8">
      {user?.role === 'dj' && (
        <>
          {!event ? (
            <></>
          ) : (
            <>
              <EventDetails onLeave={() => leaveEvent()} event={event} isDJ />
              <TabData />
              {selectedTab === 0 ? <RequestsTab /> : <QueuedSongsTab />}
            </>
          )}
        </>
      )}
    </div>
  )
}

export default DJEventView

import { ReactElement } from 'react'
import { useLocation } from 'react-router-dom'
import { useAtom } from 'jotai'
import { inviteCodeAtom } from '../store/code.store'

function Main(props: React.PropsWithChildren): ReactElement {
  const location = useLocation()
  const [, setInviteCode] = useAtom(inviteCodeAtom)
  const urlParams = new URLSearchParams(location.search)

  if (urlParams.get('code')) {
    setInviteCode(urlParams.get('code'))
  }

  // dj-code

  // venue-code

  return <>{props.children}</>
}

export default Main

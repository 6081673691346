import { ReactElement, useEffect } from 'react'
import Header from './layout/Header'
import Footer from './layout/Footer'
import Main from './layout/Main'
import { useNavigate } from 'react-router-dom'
import './App.css'

const styles = {
  Modal: {
    '.MuiBackdrop-root': {
      // backgroundColor: '#212121'
    }
  }
}

function App(): ReactElement {
  const navigate = useNavigate()

  useEffect(() => {
    navigate('/events')
  }, [])
  return (
    <div className="dark:bg-slate-900 flex flex-col h-full w-full">
      <Header></Header>

      <Main></Main>

      <Footer></Footer>
    </div>
  )
}

export default App

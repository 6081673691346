import React, { ReactElement } from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import {
  ClerkProvider,
  RedirectToSignIn,
  SignedIn,
  SignedOut,
  SignIn,
  SignUp
} from '@clerk/clerk-react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { SnackbarProvider } from 'notistack'

import Footer from './layout/Footer'
import Header from './layout/Header'
import Main from './layout/Main'
import EventsPage from './pages/EventsPage/EventsPage'
import RequestPage from './pages/RequestPage/RequestPage'
import HistoryPage from './pages/History/HistoryWrapper'
import SettingsPage from './pages/SettingsPage/SettingsPage'
import DJInvitePage from './pages/DJInvitePage'
import InviteCode from './components/InviteCode'
import tw from 'twin.macro'
import styled from '@emotion/styled'
import * as Sentry from '@sentry/react'
import DJProfile from './pages/SettingsPage/DJProfile'

const PageDivContainer = styled.div`
  padding-bottom: 56px;
  ${tw`dark:bg-slate-900 flex flex-col w-full min-h-screen relative`};
`

const clerkPubKey = import.meta.env.REACT_APP_CLERK_PUBLISHABLE_KEY

const MustBeSignedIn = (props: React.PropsWithChildren): ReactElement => (
  <>
    <SignedIn>{props.children}</SignedIn>
    <SignedOut>
      <RedirectToSignIn />
    </SignedOut>
  </>
)

const PageContainer = (props: React.PropsWithChildren): ReactElement => (
  <PageDivContainer>
    <Header></Header>
    <Main>{props.children}</Main>
    <Footer></Footer>
  </PageDivContainer>
)

const MustBeSignedInPage = (props: React.PropsWithChildren): ReactElement => (
  <MustBeSignedIn>
    <PageContainer>{props.children}</PageContainer>
  </MustBeSignedIn>
)

Sentry.init({
  dsn: 'https://a273d69b6acdd7d89cf276358763a34f@o4505724390211584.ingest.sentry.io/4505724541927424',
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ['localhost', 'https:yourserver.io/api/']
    }),
    new Sentry.Replay()
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  // replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

ReactDOM.render(
  <ClerkProvider publishableKey={clerkPubKey}>
    <SnackbarProvider>
      <React.StrictMode>
        <BrowserRouter>
          <InviteCode>
            <Routes>
              <Route path="/" element={<App />} />
              <Route
                path="/sign-in/*"
                element={
                  <PageContainer>
                    <SignIn routing="path" path="/sign-in" />
                  </PageContainer>
                }
              />
              <Route
                path="/sign-up/*"
                element={
                  <PageContainer>
                    <SignUp routing="path" path="/sign-up" />
                  </PageContainer>
                }
              />
              <Route
                path="/events"
                element={
                  <MustBeSignedInPage>
                    <EventsPage />
                  </MustBeSignedInPage>
                }
              />
              <Route
                path="/request"
                element={
                  <MustBeSignedInPage>
                    <RequestPage />
                  </MustBeSignedInPage>
                }
              />
              <Route
                path="/history"
                element={
                  <MustBeSignedInPage>
                    <HistoryPage />
                  </MustBeSignedInPage>
                }
              />
              <Route
                path="/dj-invite"
                element={
                  <>
                    {(() => {
                      window.location.assign(
                        'https://airtable.com/app9Shfo4o6vCpUhF/pags7N83OvioYGgq0/form'
                      )
                    })()}
                  </>
                }
              />
              <Route
                path="/settings"
                element={
                  <MustBeSignedInPage>
                    <SettingsPage />
                  </MustBeSignedInPage>
                }
              />
              <Route
                path="/dj-profile/:djID"
                element={
                  <MustBeSignedInPage>
                    <DJProfile />
                  </MustBeSignedInPage>
                }
              />
            </Routes>
          </InviteCode>
        </BrowserRouter>
      </React.StrictMode>
    </SnackbarProvider>
  </ClerkProvider>,
  document.getElementById('root')
)

import React, { useState, useEffect } from 'react'
import { ReactElement } from 'react'
import { Link, BrowserRouter as Router, Route } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import Tabs from '@mui/material/Tabs'
import Badge from '@mui/material/Badge'
import Tab from '@mui/material/Tab'
import AppBar from '@mui/material/AppBar'
import Fab from '@mui/material/Fab'
import AddIcon from '@mui/icons-material/Add'
import { Events } from '../../../shared/interfaces/Event'
import { useAbortableFetch } from '../../hooks/abortableFetch.hooks'
import { todayStart } from '../../utils/date.utils'
import Chip from '@mui/material/Chip'
import IconButton from '@mui/material/IconButton'
import InstagramIcon from '@mui/icons-material/Instagram'
import InputAdornment from '@mui/material/InputAdornment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSoundcloud } from '@fortawesome/free-brands-svg-icons'
import { RequestData } from '../../../shared/interfaces/Request'
import { AddToCalendarButton } from 'add-to-calendar-button-react'

interface HistoryApiResponse {
  requestCount: number
  requests: RequestData[]
}

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#93c5fd'
    },
    secondary: {
      main: '#0f172a'
    },
    success: {
      main: '#22c55e'
    }
  }
})

const formatDay = (dateStr: string) => {
  const date = new Date(dateStr)
  const formatter = new Intl.DateTimeFormat('en', {
    day: 'numeric'
  })
  const toReturn = formatter.format(date)
  return toReturn
}

const formatMonth = (dateStr: string) => {
  const date = new Date(dateStr)
  const formatter = new Intl.DateTimeFormat('en', {
    month: 'short'
  })
  const toReturn = formatter.format(date)
  return toReturn
}

const numericMonth = (dateStr: string) => {
  const date = new Date(dateStr)
  const formatter = new Intl.DateTimeFormat('en', {
    month: 'numeric'
  })
  const toReturn = formatter.format(date)
  return toReturn
}

const formatYear = (dateStr: string) => {
  const date = new Date(dateStr)
  const formatter = new Intl.DateTimeFormat('en', {
    year: 'numeric'
  })
  const toReturn = formatter.format(date)
  return toReturn
}

const today: string = todayStart()

const formatMilitaryTime = (str: string) => {
  const date = new Date(str)
  const formatter = new Intl.DateTimeFormat('en', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: false
  })
  const toReturn = formatter.format(date)
  return toReturn
}

const formatTime = (str: string) => {
  const date = new Date(str)
  const formatter = new Intl.DateTimeFormat('en', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true
  })
  const toReturn = formatter.format(date)
  return toReturn
}

const calendarDate = (str: string) => {
  const d = formatDay(str)
  const m = numericMonth(str)
  const y = formatYear(str)
  return `${y}-${m}-${d}`
}

const CurrentEvents = ({ items }: { items: any[] }): ReactElement => (
  <div className="w-full flex flex-wrap">
    {items.map((event, index) => {
      return (
        <div key={event.id} className="w-full py-4 px-4 lg:w-1/3">
          <div className="h-full flex items-start">
            <div className="w-12 flex-shrink-0 flex flex-col text-center leading-none">
              <span className="text-green-400 pb-2 mb-2 border-b-2 border-green-400 uppercase text-xs">
                {formatMonth(event?.date as string)}
              </span>
              <span className="font-medium text-xl leading-none text-green-400 title-font">
                {formatDay(event?.date as string)}
              </span>
            </div>
            <div className="flex-grow pl-6">
              <h1 className="title-font text-xl leading-none font-medium text-white mb-2">
                {event.name}
              </h1>
              <h2 className="tracking-widest text-sm title-font font-medium text-gray-400 mb-1">
                {event.address} @ {formatTime(event?.date as string)}
              </h2>
              <div className="w-full py-2">
                <AddToCalendarButton
                  name={event.name}
                  options={['Apple', 'Google']}
                  location={event?.address}
                  startDate={calendarDate(event?.date as string)}
                  endDate={calendarDate(event?.date as string)}
                  startTime={formatMilitaryTime(event?.date as string)}
                  endTime="23:59"
                  timeZone={Intl.DateTimeFormat().resolvedOptions().timeZone}
                ></AddToCalendarButton>
              </div>
            </div>
          </div>
        </div>
      )
    })}
  </div>
)

const PastEvents = ({ items }: { items: any[] }): ReactElement => (
  <div className="w-full flex flex-wrap">
    {items.map((event, index) => {
      return (
        <div key={event.id} className="w-full py-4 px-4 lg:w-1/3">
          <div className="h-full flex items-center">
            <div className="w-12 flex-shrink-0 flex flex-col text-center leading-none">
              <span className="text-gray-400 uppercase leading-none text-xs">
                {formatMonth(event?.date as string)}
              </span>
              <span className="font-medium text-xl py-1 leading-none text-gray-400 title-font">
                {formatDay(event?.date as string)}
              </span>
              <span className="text-xs leading-none text-gray-400 title-font">
                {formatYear(event?.date as string)}
              </span>
            </div>
            <div className="flex-grow pl-6">
              <h1 className="title-font text-xl font-medium text-white mb-2">
                {event.name}
              </h1>
              <h2 className="tracking-widest text-sm title-font font-medium text-gray-400 mb-1">
                {event.address}
              </h2>
            </div>
          </div>
        </div>
      )
    })}
  </div>
)

const DJProfile = (): ReactElement => {
  const { djID } = useParams()
  const [profile, setProfileData] = useState<any>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [currentEvents, setCurrentEvents] = useState<any[]>([])
  const [pastEvents, setPastEvents] = useState<any[]>([])
  const [playedRequests, setPlayedRequests] = useState<number | null>(null)
  const [selectedTab, setSelectedTab] = useState(0)

  const modifyString = (str: string) => str.replace(/-/g, ' ')

  const changeTabs = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue)
  }

  const { isLoading: currentLoading, triggerFn: listCurrentEvents } =
    useAbortableFetch<Events>({
      type: 'GET',
      url: `/api/event/dj-events/current?djs=${djID}&from=${today}`,
      authed: true,
      requestFailed({ error }) {
        console.error(error)
      },
      requestSuccessful({ data }) {
        setCurrentEvents(data.events)
        console.log('current', data.events)
      }
    })

  const { isLoading: pastLoading, triggerFn: listPastEvents } =
    useAbortableFetch<Events>({
      type: 'GET',
      url: `/api/event/dj-events/past?djs=${djID}&to=${today}`,
      authed: true,
      requestFailed({ error }) {
        console.error(error)
      },
      requestSuccessful({ data }) {
        setPastEvents(data.events)
        console.log('past', data.events)
      }
    })

  const { isLoading: reqLoading, triggerFn: getPlayedRequests } =
    useAbortableFetch<HistoryApiResponse>({
      type: 'GET',
      url: `/api/event/dj-requests?djs=${djID}&status=played`,
      authed: true,
      requestFailed({ error }) {
        console.error(error)
      },
      requestSuccessful({ data }) {
        setPlayedRequests(data.requestCount)
      }
    })

  const { isLoading: djLoading, triggerFn: loadDJ } = useAbortableFetch<Events>(
    {
      type: 'GET',
      url: `/api/dj/user?id=${djID}`,
      authed: true,
      requestFailed({ error }) {
        console.error(error)
      },
      requestSuccessful({ data }) {
        setProfileData(data)
        console.log('profile', data)
      }
    }
  )

  useEffect(() => {
    listCurrentEvents()
    listPastEvents()
    loadDJ()
    getPlayedRequests()
  }, [djID])

  const TabData = () => (
    <ThemeProvider theme={darkTheme}>
      <AppBar enableColorOnDark position="static" color="secondary">
        <Tabs variant="fullWidth" value={selectedTab} onChange={changeTabs}>
          <Tab
            label={
              <div className="flex items-center justify-between">
                <Badge
                  badgeContent={currentLoading ? 0 : currentEvents.length}
                  color="success"
                >
                  <span className="pr-2">Upcoming</span>
                </Badge>
              </div>
            }
          />
          <Tab
            label={
              <div className="flex items-center justify-between">
                <Badge
                  badgeContent={pastLoading ? 0 : pastEvents.length}
                  color="success"
                >
                  <span className="pr-2">Past Events</span>
                </Badge>
              </div>
            }
          />
        </Tabs>
      </AppBar>
    </ThemeProvider>
  )

  return (
    <>
      {!isLoading && (
        <div className="container mx-auto flex px-5 py-12 items-center justify-center flex-col">
          <section className="text-gray-400 bg-gray-900 body-font">
            <div className="flex flex-wrap mb-4">
              {!djLoading && profile && (
                <div className="p-4 w-full">
                  <div className="h-full flex sm:flex-row flex-col items-center sm:justify-start justify-center text-center sm:text-left">
                    <img
                      alt="team"
                      className="flex-shrink-0 rounded-full w-48 h-48 object-cover object-center sm:mb-0 mb-4"
                      src={
                        profile?.user?.profileImage
                          ? profile?.user?.profileImage
                          : 'https://i.pravatar.cc/200'
                      }
                    />
                    <div className="flex-grow sm:pl-8">
                      <h2 className="title-font font-medium text-2xl tracking-wider text-white">
                        {profile.name}
                      </h2>
                      <span className="inline-flex text-gray-500">
                        {profile.social.instagram && (
                          <a
                            target="_blank"
                            href={`https://instagram.com/${profile.social.instagram}`}
                          >
                            <IconButton aria-label="Instagram">
                              <InstagramIcon className="text-gray-500" />
                            </IconButton>
                          </a>
                        )}
                        {profile.social.soundCloud && (
                          <a
                            target="_blank"
                            href={`https://soundcloud.com/${profile.social.soundCloud}`}
                          >
                            <IconButton aria-label="SoundCloud">
                              <FontAwesomeIcon
                                className="text-gray-500"
                                icon={faSoundcloud}
                              />
                            </IconButton>
                          </a>
                        )}
                        {/* <a className="text-gray-500">
                          <svg
                            fill="none"
                            stroke="currentColor"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            className="w-5 h-5"
                            viewBox="0 0 24 24"
                          >
                            <path d="M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z"></path>
                          </svg>
                        </a> */}
                      </span>
                      {/* <h3 className="text-gray-500 mb-3">UI Developer</h3> */}
                      {profile.genres && (
                        <div className="flex-row flex-wrap justify-center items-center w-full mb-4">
                          {profile.genres.map((item: string) => (
                            <Chip
                              className="m-1 uppercase"
                              label={item}
                              color="primary"
                              variant="outlined"
                            />
                          ))}
                        </div>
                      )}
                      {/* <p className="mb-4">
                        DIY tote bag drinking vinegar cronut adaptogen squid
                        fanny pack vaporware.
                      </p> */}
                      <div className="flex flex-wrap -m-4 text-center">
                        <div className="p-4 sm:w-1/4 w-1/2">
                          {!pastLoading && !currentLoading && (
                            <h2 className="title-font font-medium sm:text-4xl text-3xl text-white">
                              {pastEvents.length}
                            </h2>
                          )}
                          <p className="leading-relaxed">Sets Performed</p>
                        </div>
                        <div className="p-4 sm:w-1/4 w-1/2">
                          {!reqLoading && (
                            <h2 className="title-font font-medium sm:text-4xl text-3xl text-white">
                              {playedRequests}
                            </h2>
                          )}
                          <p className="leading-relaxed">Requests Played</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </section>
          {/* <Link to="/">Back to homepage</Link> */}
          <TabData />
          {selectedTab === 0 ? (
            <CurrentEvents items={currentEvents} />
          ) : (
            <PastEvents items={pastEvents} />
          )}
        </div>
      )}
    </>
  )
}

export default DJProfile

import { ReactElement } from 'react'
import { formatDateTime } from '../utils/date.utils'
import { useAuth, useUser } from '@clerk/clerk-react'
import Divider from '@mui/material/Divider'
import Box from '@mui/material/Box'
import SongCard from './SongCard'

function RequestQueue({ requests }: { requests: any[] }): ReactElement {
  const { user } = useUser()
  const Requests = () => (
    <>
      {requests.map((req, index) => {
        const userName: string =
          (req.user?.firstName ? req.user?.firstName : '') +
          '+' +
          (req.user?.lastName ? req.user?.lastName : '')
        return (
          <>
            <article
              className={`text-baserounded-lg py-4 ${
                index > 0 ? 'border-t border-gray-200 dark:border-gray-700' : ''
              }`}
            >
              <footer className="flex justify-between items-center mb-2">
                <div className="flex items-center justify-between w-full">
                  <p className="inline-flex items-center mr-3 text-base text-gray-900 dark:text-white">
                    <img
                      className="mr-2 w-6 h-6 rounded-full"
                      src={
                        req.user?.profileImage
                          ? req.user?.profileImage
                          : `https://ui-avatars.com/api/?name=${userName}`
                      }
                      alt="Michael Gough"
                    />
                    {req.user?.firstName ? req.user?.firstName : ''}{' '}
                    {req.user?.lastName ? req.user?.lastName : ''}
                  </p>
                  <p className="text-base font-bold text-green-500">
                    {' '}
                    + ${req.tip}.00
                  </p>
                </div>
              </footer>
              <p className="text-sm flex w-full justify-between text-gray-500 dark:text-gray-400">
                <span>Requested:</span>{' '}
                <time dateTime="2022-02-08" title="February 8th, 2022">
                  {formatDateTime(req.createdAt, 't')}
                </time>
              </p>
              <div className="p-2">
                <SongCard view={'queue'} data={req.song} />
              </div>
            </article>
          </>
        )
      })}
    </>
  )

  return (
    <section className="bg-white dark:bg-gray-900 py-8 lg:py-16">
      <div className="max-w-2xl mx-auto px-4">
        <div className="flex justify-between items-center mb-2">
          <h2 className="text-lg lg:text-2xl font-bold text-gray-900 dark:text-white">
            Song Requests ({requests.length})
          </h2>
        </div>
        <Box sx={{ p: '0.5rem', bgcolor: '#0f172a' }}>
          <Requests />
        </Box>
      </div>
    </section>
  )
}

export default RequestQueue

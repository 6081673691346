import { atomWithStorage } from 'jotai/utils'
import { atom } from 'jotai'
import { Event } from '../../shared/interfaces/Event'

const eventActiveAtom = atom(false)

const eventIDAtom = atom(false)

const eventAtom = atom<Event | null>(null)

export { eventActiveAtom, eventIDAtom, eventAtom }

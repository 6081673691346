/* eslint-disable @typescript-eslint/no-explicit-any */

// Import necessary dependencies
import { ReactElement } from 'react'
import DJRequestPage from './DJRequests'
import UserRequestPage from './UserRequests'
import RoleGuard from '../../components/RoleGaurd'

/**
 * Component for rendering the request page.
 * This component displays different content based on the user's role.
 * If the user is a DJ, it shows the DJRequestPage component, and if the user is a regular user, it shows the UserRequestPage component.
 *
 * @returns {ReactElement} - JSX element representing the request page.
 */
function RequestPage(): ReactElement {
  return (
    <>
      {/* RoleGuard component for DJs */}
      <RoleGuard allow={['dj']}>
        <DJRequestPage />
      </RoleGuard>

      {/* RoleGuard component for regular users */}
      <RoleGuard allow={['user']}>
        <UserRequestPage />
      </RoleGuard>
    </>
  )
}

// Export the RequestPage component as the default export
export default RequestPage

import {
  Card,
  CardContent,
  Typography,
  CardActions,
  Button,
  TextField,
  CircularProgress
} from '@mui/material'
import { ReactElement, useEffect, useState, SyntheticEvent } from 'react'
import { useAbortableFetch } from '../../hooks/abortableFetch.hooks'
import { useSnackbar } from 'notistack'
import SocialForm from './UpdateSocialForm'
import InstagramIcon from '@mui/icons-material/Instagram'
import InputAdornment from '@mui/material/InputAdornment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSoundcloud } from '@fortawesome/free-brands-svg-icons'
import Chip from '@mui/material/Chip'
import Autocomplete from '@mui/material/Autocomplete'
import Stack from '@mui/material/Stack'
import Checkbox from '@mui/material/Checkbox'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import IconButton from '@mui/material/IconButton'
import CommentIcon from '@mui/icons-material/Comment'
import { seedGenres } from '../../store/dj.store'

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
const checkedIcon = <CheckBoxIcon fontSize="small" />

interface DJ {
  name: string
  social: {
    instagram?: string
    soundCloud?: string
  }
  genres: any[]
  createdAt?: string
  updatedAt?: string
  id?: string
  user?: string
}

function DJCard(): ReactElement {
  const [djForm, setDjForm] = useState<DJ>({
    name: '',
    social: {
      instagram: '',
      soundCloud: ''
    },
    genres: []
  })

  const [defaultForm, setDefaultForm] = useState<DJ>({
    name: '',
    social: {
      instagram: '',
      soundCloud: ''
    },
    genres: []
  })
  const [isEditable, setEditable] = useState<boolean>(false)
  const [socialForm, setSocialForm] = useState<boolean>(false)

  const { enqueueSnackbar } = useSnackbar()

  const openSnackbar = (key: string, message: string) =>
    enqueueSnackbar({
      key,
      anchorOrigin: {
        horizontal: 'left',
        vertical: 'bottom'
      },
      TransitionProps: {
        direction: 'up'
      },
      message,
      preventDuplicate: true,
      persist: false,
      autoHideDuration: 2_500
    })

  const { isLoading, triggerFn: loadDJ } = useAbortableFetch<DJ>({
    type: 'GET',
    url: '/api/dj/me',
    authed: true,
    requestFailed({ error }) {
      console.error(error)
      openSnackbar('dj.updated', 'Update Failed')
    },
    requestSuccessful({ data }) {
      // setDjForm(data)
      setDjForm((prevForm) => ({
        ...prevForm,
        name: data.name,
        social: {
          ...prevForm.social,
          instagram: data.social.instagram,
          soundCloud: data.social.soundCloud
        },
        genres: data.genres
      }))
      setDefaultForm((prevForm) => ({
        ...prevForm,
        name: data.name,
        social: {
          ...prevForm.social,
          instagram: data.social.instagram,
          soundCloud: data.social.soundCloud
        },
        genres: data.genres
      }))
      if (data.genres.length === 5) setMaxSelected(true)
    }
  })

  useEffect(() => {
    loadDJ()
  }, [])

  const { isLoading: isSaving, triggerFn: createDJ } = useAbortableFetch({
    type: 'PUT',
    url: '/api/dj/me',
    authed: true,
    headers: {
      'Content-Type': 'application/json'
    },
    body: djForm,
    requestFailed({ error }) {
      console.log(error)
      openSnackbar('dj.updated', 'Update Failed')
      setEditable(false)
    },
    requestSuccessful() {
      openSnackbar('dj.updated', 'DJ Details Updated')
      setEditable(false)
      loadDJ()
    }
  })

  const cancelChanges = () => {
    openSnackbar('dj.updated', 'Changes Canceled')
    setDjForm((prevForm) => ({
      ...prevForm,
      name: defaultForm.name,
      social: {
        ...prevForm.social,
        instagram: defaultForm.social.instagram,
        soundCloud: defaultForm.social.soundCloud
      },
      genres: defaultForm.genres
    }))
    if (defaultForm.genres.length < 5) setMaxSelected(false)
    setEditable(false)
    console.log(djForm)
  }

  const updateForm = (prop: string, value: string) => {
    setDjForm((prevForm) => ({
      ...prevForm,
      social: {
        ...prevForm.social,
        [prop]: value
      }
    }))
  }

  const handleChanges = (newValue: string[]) => {
    console.log(newValue.length, 'newValue')
    if (newValue?.length <= 5) {
      // setValue([...(newValue as string[])])
      setDjForm({
        ...djForm,
        genres: [...(newValue as string[])]
      })
      if (newValue?.length === 5) {
        setMaxSelected(true)
      } else {
        setMaxSelected(false)
      }
    }
  }
  // const [shouldDisableInput] = djForm.genres.length >= 5
  const [maxSelected, setMaxSelected] = useState<boolean>(false)

  const modifyString = (str: string) => str.replace(/-/g, ' ')

  return (
    <Card sx={{ minWidth: 275 }}>
      <CardContent>
        <Typography variant="h5" component="div">
          DJ Profile
        </Typography>
      </CardContent>
      {isLoading || isSaving ? (
        <div className="flex flex-row justify-center items-center w-full">
          <CircularProgress />
        </div>
      ) : (
        <div className="w-full lg:px-24 lg:space-y-12 space-y-4 md:space-y-0 pt-6">
          <div className="flex flex-col md:flex-row md:justify-around md:items-center w-full lg:space-x-12 space-y-4 md:space-y-0 px-3">
            <TextField
              disabled={!isEditable}
              id="outlined-basic"
              label="Stage Name"
              variant="outlined"
              fullWidth
              value={djForm.name}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setDjForm({
                  ...djForm,
                  name: event.target.value
                })
              }}
            />
            {/* TODO:  deprecated */}
            <div className="w-full flex flex-row">
              <Autocomplete
                disabled={!isEditable}
                multiple
                fullWidth
                size="small"
                options={seedGenres}
                getOptionLabel={(option) => option}
                value={djForm.genres}
                onChange={(
                  event: SyntheticEvent<Element, Event>,
                  newValue: string[] | null
                ) => {
                  handleChanges(newValue as string[])
                }}
                renderOption={(props, option, { selected }) => (
                  <ListItem
                    {...props}
                    key={option}
                    disabled={maxSelected && !selected}
                    disablePadding
                    dense
                  >
                    <ListItemButton
                      disabled={maxSelected && !selected}
                      role={undefined}
                      dense
                    >
                      <ListItemIcon>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                          disabled={maxSelected && !selected}
                        />
                      </ListItemIcon>
                      <ListItemText id={option} primary={option} />
                    </ListItemButton>
                  </ListItem>
                )}
                style={{ width: 500 }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    disabled={maxSelected}
                    label="Genres"
                    placeholder=" Type to search genres"
                    helperText={
                      maxSelected
                        ? 'You have selected the max number of genres.'
                        : 'You may add up to 5 genres to your profile'
                    }
                  />
                )}
              />
            </div>
            {!isEditable && (
              <>
                <div className="w-full bg-gray-200 bg-opacity-50 rounded-lg p-4 flex flex-col">
                  <h2 className="text-gray-900 text-lg capitalize font-medium title-font mb-5">
                    Instagram
                  </h2>
                  <div className="relative mb-4">
                    <TextField
                      id="outlined-controlled"
                      disabled={!isEditable}
                      fullWidth
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <>
                              <InstagramIcon />
                            </>
                          </InputAdornment>
                        )
                      }}
                      label="Username"
                      value={djForm.social.instagram}
                    />
                  </div>
                </div>
                <div className="w-full bg-gray-200 bg-opacity-50 rounded-lg p-4 flex flex-col">
                  <h2 className="text-gray-900 text-lg capitalize font-medium title-font mb-5">
                    SoundCloud
                  </h2>
                  <div className="relative mb-4">
                    <TextField
                      id="outlined-controlled"
                      disabled={!isEditable}
                      fullWidth
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <FontAwesomeIcon icon={faSoundcloud} size="xl" />
                          </InputAdornment>
                        )
                      }}
                      label="Username"
                      value={djForm.social.soundCloud}
                    />
                  </div>
                </div>
              </>
            )}
            {isEditable && !isLoading && (
              <>
                <SocialForm
                  editable={isEditable}
                  value={djForm.social.instagram as string}
                  handleConfirm={updateForm}
                  account="instagram"
                />
                <SocialForm
                  editable={isEditable}
                  value={djForm.social.soundCloud as string}
                  handleConfirm={updateForm}
                  account="soundCloud"
                />
              </>
            )}
          </div>
        </div>
      )}

      <CardActions>
        <div className="flex flex-row justify-center items-center w-full mt-8">
          {!isEditable && (
            <Button
              size="medium"
              variant="contained"
              onClick={() => setEditable(true)}
            >
              Update DJ Info
            </Button>
          )}
          {isEditable && (
            <>
              <div className="mr-2">
                <Button
                  color="error"
                  size="medium"
                  variant="contained"
                  onClick={() => cancelChanges()}
                >
                  Cancel
                </Button>
              </div>
              <Button
                size="medium"
                variant="contained"
                onClick={() => createDJ()}
              >
                Submit changes
              </Button>
            </>
          )}
        </div>
      </CardActions>
    </Card>
  )
}

export default DJCard

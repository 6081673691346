/* eslint-disable @typescript-eslint/no-explicit-any */

// Import necessary dependencies
import RoleGuard from '../../components/RoleGaurd'
import { ReactElement, useEffect, useState, useRef } from 'react'
import { useAtom } from 'jotai'
import { DJI } from '../../../shared/interfaces/DJ'
import { useAbortableFetch } from '../../hooks/abortableFetch.hooks'
import { useUser } from '../../hooks/user.hooks'
import { djIdAtom } from '../../store/dj.store'
import HistoryPage from './HistoryPage'
import { RequestData, RequestStatus } from '../../../shared/interfaces/Request'
import { dateToMS } from '../../utils/date.utils'

interface Data {
  name: string
  tip: number
  eventName: string
  eventDate: number
  requestStatus: RequestStatus
  requestId?: string
}

interface HistoryApiResponse {
  requestCount: number
  requests: RequestData[]
}

const formatRowData = (data: RequestData[]): Data[] => {
  const rows: any[] = []
  data.map((row) => {
    const rowData: Data = {
      name: row.song.name,
      tip: row.tip,
      eventName: row.event.name,
      eventDate: dateToMS(row.createdAt),
      requestStatus: row.status,
      requestId: row.id
    }
    rows.push(rowData as Data)
  })
  return rows as Data[]
}

/**
 * Component for rendering the request page.
 * This component displays different content based on the user's role.
 * If the user is a DJ, it shows the DJRequestPage component, and if the user is a regular user, it shows the UserRequestPage component.
 *
 * @returns {ReactElement} - JSX element representing the request page.
 */
function HistoryWrapper(): ReactElement {
  const { user } = useUser()
  const [djRows, updateDJRows] = useState<Data[] | any[]>([])
  const [userRows, updateUserRows] = useState<Data[] | any[]>([])
  const [djID, setDjID] = useAtom(djIdAtom)
  // const initialRender = useRef(true)

  const { triggerFn: loadDJId } = useAbortableFetch<DJI>({
    type: 'GET',
    url: '/api/dj/me',
    authed: true,
    requestFailed({ error }) {
      console.error(error)
    },
    requestSuccessful({ data }) {
      setDjID(data.id as string)
    }
  })

  const { isLoading: userLoading, triggerFn: getUserHistory } =
    useAbortableFetch<HistoryApiResponse>({
      type: 'GET',
      url: '/api/user/history',
      authed: true,
      requestFailed({ error }) {
        console.error(error)
      },
      requestSuccessful({ data }) {
        const formatted: Data[] = formatRowData(data.requests)
        updateUserRows(formatted)
      }
    })

  const { isLoading: djLoading, triggerFn: getDJHistory } =
    useAbortableFetch<HistoryApiResponse>({
      type: 'GET',
      url: `/api/event/dj-requests?djs=${djID}`,
      authed: true,
      requestFailed({ error }) {
        console.error(error)
      },
      requestSuccessful({ data }) {
        const formatted: Data[] = formatRowData(data.requests)
        updateDJRows(formatted)
      }
    })

  useEffect(() => {
    if (!!user && user?.role === 'dj') {
      if (!djID) {
        loadDJId().then(() => getDJHistory())
      }
      getDJHistory()
    } else {
      getUserHistory()
    }
  }, [djID, user])

  return (
    <>
      {/* RoleGuard component for DJs */}
      <RoleGuard allow={['dj']}>
        <HistoryPage isLoading={djLoading} rowData={djRows} />
      </RoleGuard>

      {/* RoleGuard component for regular users */}
      <RoleGuard allow={['user']}>
        <HistoryPage isLoading={userLoading} rowData={userRows} />
      </RoleGuard>
    </>
  )
}

// Export the RequestPage component as the default export
export default HistoryWrapper

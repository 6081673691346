import { ReactElement, useState, useEffect } from 'react'
import { Event, NewEvent } from '../../../shared/interfaces/Event'
import { Button } from '@mui/material'
import CreateEventDialog from './CreateEventDialog'
import { inviteCodeAtom } from '../../store/code.store'
import { useAtom } from 'jotai'
import { eventAtom } from '../../store/eventSession.store'
import { useAuth } from '@clerk/clerk-react'
import { useUser } from '../../hooks/user.hooks'
import { djIdAtom } from '../../store/dj.store'
import EventsList from '../../components/EventsList'
import CircularProgress from '@mui/material/CircularProgress'
import dayjs from 'dayjs'

const noop = () => {}

const CircularIndeterminate = () => {
  return (
    <div className="w-full h-full flex flex-grow items-start justify-center p-6">
      <CircularProgress size={'6rem'} />
    </div>
  )
}

const FormDefault: NewEvent = {
  name: '',
  date: dayjs().set('hour', 21).set('minute', 0).set('second', 0),
  address: '',
  restrictToFree: false
}

function DJEvents({ onCreated }: { onCreated: () => void }) {
  const [open, setOpen] = useState(false)
  const [newEvent, setNewEvent] = useState<NewEvent>(FormDefault)
  const { getToken } = useAuth()
  const { user } = useUser()
  const [inviteCode, setInviteCode] = useAtom(inviteCodeAtom)
  const [event, setEvent] = useAtom(eventAtom)
  const [djID, setDjID] = useAtom(djIdAtom)

  const formatReqBody = (_event: NewEvent) => {
    const dt = _event.date?.toDate()
    const formatted = {
      ..._event,
      date: dt?.toString(),
      dj: djID
    }
    console.log(formatted)
    return formatted
  }
  const updateForm = (data: any) => {
    console.log('data', data)
    setNewEvent(data as NewEvent)
  }
  const handleSubmit = (data: any) => {
    const reqBody = formatReqBody(data)
    getToken().then((token) => {
      fetch(`/api/event/create`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(reqBody)
      }).then(async (response) => {
        const resp = await response.json()
      })
    })
    closeAndReset()
  }

  const closeAndReset = () => {
    setNewEvent(FormDefault)
    setOpen(false)
    onCreated()
  }

  return (
    <div>
      <Button variant="outlined" onClick={() => setOpen(true)}>
        Create New Event
      </Button>
      <CreateEventDialog
        form={newEvent}
        onInputChange={updateForm}
        isOpen={open}
        dialogClose={() => closeAndReset()}
        submitForm={handleSubmit}
        required={false}
      />
    </div>
  )
}

function EventsListView({
  events,
  selectEvent,
  role,
  isLoading,
  onCreated = noop
}: {
  events: any[]
  selectEvent: (code: string) => any
  role: string
  isLoading: boolean
  onCreated?: () => void
}): ReactElement {
  return (
    <div className="container text-white flex flex-col flex-grow h-full w-full text-center p-4">
      {role === 'dj' ? (
        <>
          <div className="flex flex-wrap w-full">
            <div className="p-4 w-full mb-6 lg:mb-0 lg:w-1/2">
              <h1 className="text-start tracking-wide font-medium title-font mb-2 text-white text-2xl sm:text-3xl">
                My Events
              </h1>
              <div className="h-1 w-20 bg-blue-500 rounded"></div>
            </div>
          </div>
          {!isLoading ? (
            <>
              <DJEvents onCreated={onCreated} />
              <EventsList onSelect={selectEvent} items={events as any[]} />
            </>
          ) : (
            <CircularIndeterminate />
          )}
        </>
      ) : (
        <>
          {!isLoading ? (
            <>
              <div className="flex flex-wrap w-full">
                <div className="p-4 w-full mb-0">
                  <h1 className="text-start tracking-wide font-medium title-font mb-2 text-white text-2xl sm:text-3xl">
                    Events
                  </h1>
                  <div className="h-1 w-20 bg-blue-500 rounded"></div>
                </div>
              </div>
              <EventsList onSelect={selectEvent} items={events as any[]} />
            </>
          ) : (
            <CircularIndeterminate />
          )}
        </>
      )}
    </div>
  )
}

export default EventsListView

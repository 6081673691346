import { SignOutButton, useAuth } from '@clerk/clerk-react'
import { ReactElement, useState } from 'react'
import { Link } from 'react-router-dom'
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner'
import QrCodeIcon from '@mui/icons-material/QrCode'
import NotificationsIcon from '@mui/icons-material/Notifications'
import PaidIcon from '@mui/icons-material/Paid'
import {
  Badge,
  Box,
  Button,
  Menu,
  MenuItem,
  Modal,
  Typography
} from '@mui/material'
import React from 'react'
import { useUser } from '../hooks/user.hooks'
import QRCodeDialog from '../components/QRCode'
import { useAtom } from 'jotai'
import { inviteCodeAtom } from '../store/code.store'
import { styled } from 'styled-components'
import tw from 'twin.macro'
import QRCodeModal from './QRCodeModal'
import Icons from '../icons'

function Header(): ReactElement {
  const { userId } = useAuth()
  const { user } = useUser()
  const [inviteCode] = useAtom(inviteCodeAtom)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const [dialogOpen, setDialogOpen] = useState(false)
  const handleDialogOpen = () => setDialogOpen(true)
  const handleDialogClose = () => setDialogOpen(false)

  const AuthButton = styled.button`
    ${tw`py-3 px-4 inline-flex justify-center items-center gap-2 rounded-md bg-blue-100 border border-transparent font-semibold text-blue-500 transition-all text-sm`}
    ${tw`hover:text-black hover:bg-blue-300`}
    ${tw`focus:outline-none focus:ring-2 ring-offset-white focus:ring-blue-500 focus:ring-offset-2`}
    ${tw`dark:focus:ring-offset-gray-800`}
  `

  const HeaderContainer = styled.header`
    ${tw`flex justify-start flex-nowrap z-50 w-full bg-white border-b border-gray-200 text-sm py-4`}
    ${tw`dark:bg-gray-800 dark:border-gray-700`}
  `

  return (
    <HeaderContainer>
      <QRCodeModal
        dialogOpen={dialogOpen}
        handleDialogClose={handleDialogClose}
      />
      <nav
        className="max-w-[85rem] w-full mx-auto px-6 flex items-center justify-between sm:px-8 lg:px-10"
        aria-label="Global"
      >
        <div className="flex items-center justify-between">
          <Link
            to={'/'}
            className="flex-none text-xl font-semibold dark:text-white"
          >
            <div className="w-[50px]">
              <Icons.HeyDJ></Icons.HeyDJ>
            </div>
          </Link>
        </div>

        <div
          id="navbar-collapse-with-animation"
          className="hs-collapse overflow-hidden transition-all duration-300 basis-full grow block"
        >
          <div className="flex gap-3 flex-row items-center justify-end mt-0 pl-5">
            {user && user.role === 'dj' ? (
              <Button
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleDialogOpen}
              >
                <QrCodeIcon color={'info'}></QrCodeIcon>
              </Button>
            ) : (
              <Button
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleDialogOpen}
              >
                <QrCodeScannerIcon color={'info'}></QrCodeScannerIcon>
              </Button>
            )}

            {userId ? (
              <SignOutButton
                children={<AuthButton type="button">Log Out</AuthButton>}
              />
            ) : (
              <Link to={'/sign-in'}>
                <AuthButton type="button">Login</AuthButton>
              </Link>
            )}
          </div>
        </div>
      </nav>
    </HeaderContainer>
  )
}

export default Header

import { PaymentElement } from '@stripe/react-stripe-js'
import { useState } from 'react'
import { useStripe, useElements } from '@stripe/react-stripe-js'

export default function CheckoutForm({
  onFormSubmit
}: {
  onFormSubmit: (paymentMethodID: string) => void
}) {
  const stripe = useStripe()
  const elements = useElements()

  const [message, setMessage] = useState<any | null>(null)
  const [isProcessing, setIsProcessing] = useState(false)

  const handleSubmit = async (e: any) => {
    e.preventDefault()

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return
    }

    setIsProcessing(true)

    // confirmSetup

    const { error, setupIntent } = await stripe.confirmSetup({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: `${window.location.origin}/events`
      },
      redirect: 'if_required'
    })

    if (
      error &&
      (error.type === 'card_error' || error.type === 'validation_error')
    ) {
      setMessage(error.message)
    } else {
      setMessage('An unexpected error occured.')
    }

    if (setupIntent?.status === 'succeeded' && setupIntent?.payment_method)
      onFormSubmit(
        typeof setupIntent.payment_method === 'string'
          ? setupIntent.payment_method
          : setupIntent.payment_method.id
      )
    else setIsProcessing(false)
  }

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <PaymentElement id="payment-element" />
      <div className="p-4 flex flex-row justify-center items-center">
        <button
          disabled={isProcessing || !stripe || !elements}
          id="submit"
          className="font-bold py-2 px-4 rounded bg-blue-500 hover:bg-blue-700 text-white"
          type="submit"
        >
          {isProcessing ? 'Processing ... ' : 'Request Song'}
        </button>
      </div>
      {/* Show any error or success messages */}
      {message && <div id="payment-message">{message}</div>}
    </form>
  )
}

import { useUser } from '../../hooks/user.hooks'
import {
  Card,
  CardContent,
  Typography,
  CardActions,
  Button,
  CircularProgress
} from '@mui/material'
import { ReactElement, useEffect, useState } from 'react'
import { useAbortableFetch } from '../../hooks/abortableFetch.hooks'
import type { PaymentMethod } from '@stripe/stripe-js'
import CreditCard from '../../components/CreditCard'

interface CardsResponse {
  object: 'list'
  data: PaymentMethod[]
}

function BillingCard(): ReactElement {
  const { user } = useUser()

  const [cards, setCards] = useState<PaymentMethod[]>([])

  const [selectedCard, setSelectedCard] = useState(-1)

  const Cards = ({ data }: { data: PaymentMethod[] }) => (
    <div className="bg-white mx-auto flex flex-wrap justify-center items-center p-2 lg:p-4 container">
      {data.map((card, index) => {
        return (
          <div className="w-full p-4 md:w-1/2 lg:w-1/3">
            <CreditCard
              info={card}
              selected={index === selectedCard}
              onClick={() => {
                if (index === selectedCard) setSelectedCard(-1)
                else setSelectedCard(index)
              }}
            />
          </div>
        )
      })}
    </div>
  )

  const { isLoading: isCardListLoading, triggerFn: loadCards } =
    useAbortableFetch<CardsResponse>({
      type: 'GET',
      url: '/api/billing/cards',
      authed: true,
      requestSuccessful({ data }) {
        setCards(data.data)
      }
    })

  const { isLoading: isCardRemovalLoading, triggerFn: deleteCard } =
    useAbortableFetch<CardsResponse>({
      type: 'DELETE',
      url: '/api/billing/card',
      authed: true,
      responseType: 'TEXT',
      requestSuccessful() {
        loadCards()
        setSelectedCard(-1)
      }
    })

  useEffect(() => {
    loadCards()
  }, [])

  return (
    <Card sx={{ minWidth: 275 }}>
      <CardContent>
        <Typography variant="h5" component="div">
          Payment Settings
        </Typography>
        <Typography sx={{ mb: 1.5 }} color="text.secondary">
          Saved Cards
        </Typography>
        {isCardListLoading || isCardRemovalLoading ? (
          <CircularProgress />
        ) : (
          <Cards data={cards as PaymentMethod[]} />
        )}
      </CardContent>
      <CardActions>
        <div className="flex flex-row justify-center items-center w-full p-3">
          <Button
            size="medium"
            variant="contained"
            color="error"
            onClick={() =>
              deleteCard({
                url: `/api/billing/card?card=${cards[selectedCard].id}`
              })
            }
            disabled={selectedCard === -1}
          >
            Delete Saved Card
          </Button>
        </div>
      </CardActions>
    </Card>
  )
}

export default BillingCard

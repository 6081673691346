import { ReactElement } from 'react'

interface SpotifySearchResponse {
  song: {
    name: string
    artists: string[]
    // Required by Spotify
    spotifyURL: string
  }
  album: {
    name: string
    albumArt: {
      height: number
      width: number
      url: string
    }
  }
}

interface RequestListResponse {
  name: string
  source: string
  sourceId: string
  artist: string[]
  albumArt: string
}

function SongCard({
  data,
  view
}: {
  data: SpotifySearchResponse | RequestListResponse
  view: 'search' | 'queue'
}): ReactElement {
  const albumArt: string =
    view === 'search'
      ? (data as SpotifySearchResponse).album.albumArt.url
      : (data as RequestListResponse).albumArt
  const songName: string =
    view === 'search'
      ? (data as SpotifySearchResponse).song.name
      : (data as RequestListResponse).name
  const artists: string[] =
    view === 'search'
      ? (data as SpotifySearchResponse).song.artists
      : (data as RequestListResponse).artist
  return (
    <div className="bg-white h-full flex items-center p-0 rounded-lg">
      <img
        alt="team"
        className="-ml-1 aspect-square w-16 h-full bg-gray-100 object-cover object-center rounded-l-lg flex-shrink-0 mr-4"
        src={albumArt}
      />
      <div className="flex-grow">
        <h2 className="text-gray-900 title-font font-medium text-sm">
          {songName.slice(0, 70)}
          {songName.length > 70 ? '...' : ''}
        </h2>
        <p className="text-gray-500 text-sm text-ellipsis overflow-hidden">
          {artists.join(', ')}
        </p>
      </div>
    </div>
  )
}

export default SongCard

export const dateToMS = (str: string) => {
  const date = new Date(str)
  const ms = date.getTime()
  return ms
}

export const formatTime = (str: string) => {
  const date = new Date(str)
  const formatter = new Intl.DateTimeFormat('en', {
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    hour12: true
  })
  const toReturn = formatter.format(date)
  return toReturn
}

export type DateTimeFormat = 'd' | 't' | 'dt'

export const formatDateTime = (ms: string, format: DateTimeFormat) => {
  const time = formatTime(ms)
  const date = new Date(ms).toLocaleDateString()
  const dateOrTime = format === 'd' ? date : time
  const toReturn = format === 'dt' ? date + ' – ' + time : dateOrTime
  return toReturn
}

export const formatDate = (ms: string) => {
  const time = formatTime(ms)
  const date = new Date(ms).toLocaleDateString()
  const formatted = time + ' – ' + date
  return formatted
}

export const todayStart = (): string => {
  const now = Date.now()
  const dt = new Date(now)
  dt.setHours(0, 0, 0, 0)
  return dt.toISOString()
}

export const todayEnd = (): string => {
  const now = Date.now()
  const dt = new Date(now)
  dt.setHours(23, 59, 59)
  return dt.toISOString()
}

export const lastDayOfMonth = (y: number, m: number): number => {
  return new Date(y, m + 1, 0).getDate()
}
// lastday(2014,0) -> 31
// lastday(2014,1) -> 28

export const monthEnd = () => {
  const now = Date.now()
  const dt = new Date(now)
  const day = lastDayOfMonth(dt.getFullYear(), dt.getMonth())
  dt.setDate(day)
  dt.setHours(23, 59, 59)
  return dt.toISOString()
}

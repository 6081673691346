/* eslint-disable @typescript-eslint/no-explicit-any */
import { useAuth } from '@clerk/clerk-react'
import { useUser } from '../../hooks/user.hooks'
import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText
} from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import { DemoItem } from '@mui/x-date-pickers/internals/demo'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { Dayjs } from 'dayjs'
import { TextField, Button } from '@mui/material'
import { ReactElement, useEffect, useState } from 'react'
import { inviteCodeAtom } from '../../store/code.store'
import { useAtom } from 'jotai'
import { eventAtom, eventIDAtom } from '../../store/eventSession.store'
import { Event } from '../../../shared/interfaces/Event'
import { styled } from 'styled-components'
import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'
import IconButton from '@mui/material/IconButton'
import Stack from '@mui/material/Stack'
import tw from 'twin.macro'
import EventDetails from '../../components/EventDetails'

interface NewEvent {
  name: string
  date?: Dayjs | null
  address?: string
}

function DJRequestPage(): ReactElement {
  const { getToken } = useAuth()
  const { user } = useUser()
  const [inviteCode, setInviteCode] = useAtom(inviteCodeAtom)
  const [event, setEvent] = useAtom(eventAtom)
  const [requests, setRequests] = useState<any[]>([])

  useEffect(() => {
    // Set up the interval when the component mounts
    const interval = setInterval(getSongRequests, 3000)
    getSongRequests()
    // Clean up the interval when the component unmounts to avoid memory leaks
    return () => clearInterval(interval)
  }, [event]) // The empty dependency array ensures the effect runs only once on mount

  // Create Event Dialog
  const [open, setOpen] = useState(false)

  const submitEvent = () => {
    const dt = newEvent.date?.toDate()
    const formattedEvent = {
      ...newEvent,
      date: dt?.toString(),
      dj: '64b30431d0ee3f36c9accdb9',
      code: '1234'
    }
    getToken().then((token) => {
      fetch(`/api/event/create`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(formattedEvent)
      }).then(async (response) => {
        const resp = await response.json()
        setInviteCode(resp?.code)
      })
    })
    setOpen(false)
    resetForm()
  }

  const [newEvent, setNewEvent] = useState<NewEvent>({
    name: '',
    date: null,
    address: ''
  })

  const resetForm = () => {
    setNewEvent({
      name: '',
      date: null,
      address: ''
    })
  }

  const loadEvent = () => {
    getToken().then((token) => {
      fetch(`/api/event?code=${inviteCode}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      }).then(async (response) => {
        const resp: Event = await response.json()
        setEvent(resp)
      })
    })
  }

  useEffect(() => {
    loadEvent()
  }, [inviteCode])

  const leaveEvent = () => {
    setInviteCode(null)
    setEvent(null)
  }

  const getSongRequests = () => {
    if (event?.id) {
      getToken().then((token) => {
        fetch(`/api/event/request?event=${event?.id}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
          }
        })
          .then((response) => response.json())
          .then((resp) => {
            setRequests(resp.requests)
          })
      })
    }
  }

  const reactToSong = (id: string, status: string) => {
    getToken().then((token) => {
      fetch(`/api/event/request/reaction`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({
          reaction: status,
          request: id
        })
      }).then(getSongRequests)
    })
  }

  const RequestList = ({ items }: { items: any[] }) => (
    <>
      {items.map((item, index) => {
        return (
          <div key={item.id} className="p-2 w-full">
            <div className="h-full flex justify-between items-center border-gray-800 border p-4 rounded-lg">
              <div className="flex-grow">
                <h2 className="text-white title-font font-medium">
                  {item.song?.name}
                </h2>
                <p className="text-gray-600">{item.song?.artist[0]}</p>
              </div>
              <div className="flex flex-col">
                <span className="title-font font-medium text-2xl text-white">
                  ${item.tip}.00
                </span>
                {/* <p className="text-gray-600">{item.user?.firstName} {item.user?.lastName}</p> */}
                <Stack direction="row">
                  <IconButton
                    aria-label="Accept"
                    color="primary"
                    onClick={() => reactToSong(item.id as string, 'accepted')}
                  >
                    <CheckIcon />
                  </IconButton>
                  <IconButton
                    aria-label="Reject"
                    color="secondary"
                    onClick={() => reactToSong(item.id as string, 'denied')}
                  >
                    <CloseIcon />
                  </IconButton>
                </Stack>
              </div>
            </div>
          </div>
        )
      })}
    </>
  )

  return (
    <div className="flex flex-col w-full h-full px-2 space-x-1 mt-2 pb-24">
      {user?.role === 'dj' && (
        <div>
          {!event ? (
            <></>
          ) : (
            <EventDetails onLeave={() => leaveEvent()} event={event} />
          )}
          <Button variant="outlined" onClick={() => setOpen(true)}>
            Create Event
          </Button>
          <Dialog open={open} onClose={() => setOpen(false)}>
            <DialogTitle>Create New Event</DialogTitle>
            <DialogContent>
              <DialogContentText>
                To create a new event, please fill out the form below.
              </DialogContentText>
              <TextField
                autoFocus
                margin="dense"
                id="name"
                label="Event Name"
                type="text"
                fullWidth
                variant="standard"
                value={newEvent.name}
                onChange={(_event: React.ChangeEvent<HTMLInputElement>) => {
                  setNewEvent({
                    ...newEvent,
                    name: _event.target.value
                  })
                }}
              />
              <div className="py-6 flex w-full">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoItem label="Event Date (optional)">
                    <DatePicker
                      value={newEvent.date}
                      onChange={(newValue) => {
                        setNewEvent({
                          ...newEvent,
                          date: newValue
                        })
                      }}
                    />
                  </DemoItem>
                </LocalizationProvider>
              </div>
              <TextField
                autoFocus
                margin="dense"
                id="address"
                label="Event Address (optional)"
                type="text"
                fullWidth
                variant="standard"
                value={newEvent.address}
                onChange={(_event: React.ChangeEvent<HTMLInputElement>) => {
                  setNewEvent({
                    ...newEvent,
                    address: _event.target.value
                  })
                }}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setOpen(false)}>Cancel</Button>
              <Button onClick={submitEvent}>Submit</Button>
            </DialogActions>
          </Dialog>
        </div>
      )}
      {/* request list */}
      <div className="flex flex-col flex-wrap">
        <RequestList items={requests as any} />
      </div>
    </div>
  )
}

export default DJRequestPage

import { ReactElement } from 'react'
import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
  InputAdornment,
  Button,
  TextField
} from '@mui/material'
import AddCash from './../AddCash'

const PageTitles = ['Request Song', 'Enter Payment Information']

type SERVICES =
  | 'Spotify'
  | 'Apple Music'
  | 'Youtube Music'
  | 'SoundCloud'
  | 'None'

interface SongRequestForm {
  event: string
  name: string
  source: SERVICES
  sourceId: string
  artist: any[]
  tip: number
}

function AddTipDialog({
  isOpen,
  dialogClose,
  songSubmit,
  selectedSong,
  event,
  currentService,
  tip,
  updateTip,
  step,
  setStepper
}: {
  isOpen: boolean
  dialogClose: () => void
  songSubmit: (body: any) => void
  selectedSong: any | null
  event: any
  currentService: SERVICES
  tip: number
  updateTip: (num: number) => void
  step: number
  setStepper: (num: number) => void
}): ReactElement {
  const createFormData = () => {
    const form: SongRequestForm = {
      event: event?.id,
      name: selectedSong?.song.name,
      source: currentService,
      sourceId: selectedSong?.song.spotifyURL,
      artist: selectedSong?.song.artists,
      tip
    }
    return form
  }

  const Step1 = () => {
    return (
      <DialogContent>
        <DialogContentText>
          Request {selectedSong?.song.name} for the event {event?.name}
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="tip"
          label="DJ Tip (optional)"
          fullWidth
          variant="standard"
          value={tip}
          onChange={(_event: React.ChangeEvent<HTMLInputElement>) => {
            const value = Number(_event.target.value)
            if (!Number.isNaN(value)) updateTip(value)
          }}
          inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>
          }}
        />
      </DialogContent>
    )
  }

  const Step2 = () => {
    return (
      <DialogContent>
        <DialogContentText>Tip {'$' + tip + '.00'} to the DJ</DialogContentText>
        <AddCash
          onFormSubmit={(paymentMethodID: string) =>
            songSubmit(createFormData())
          }
          amount={tip * 100}
          djID={event.dj.id}
        ></AddCash>
      </DialogContent>
    )
  }

  return (
    <Dialog
      open={isOpen}
      onClose={() => dialogClose()}
      fullWidth={true}
      maxWidth={'sm'}
    >
      <DialogTitle align="center">{PageTitles[step]}</DialogTitle>
      {step === 0 ? <Step1 /> : <Step2 />}
      <DialogActions>
        <div className="flex flex-row w-full justify-start items-start">
          {step === 1 && <Button onClick={() => setStepper(0)}>Prev</Button>}
          <Button onClick={() => dialogClose()}>Cancel</Button>
          {step === 0 && (
            <Button
              onClick={() =>
                tip > 0 ? setStepper(1) : songSubmit(createFormData())
              }
            >
              Submit
            </Button>
          )}
        </div>
      </DialogActions>
    </Dialog>
  )
}
export default AddTipDialog

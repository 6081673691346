import { ReactElement } from 'react'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import Skeleton from '@mui/material/Skeleton'

function TableRowsLoader({
  rowsNum,
  colsNum
}: {
  rowsNum: number
  colsNum: number
}): ReactElement {
  return (
    <>
      {[...Array(rowsNum)].map((row, index) => (
        <TableRow key={index}>
          {[...Array(colsNum)].map((col, index) => (
            <TableCell key={index}>
              <Skeleton animation="wave" variant="text" />
            </TableCell>
          ))}
        </TableRow>
      ))}
    </>
  )
}
export default TableRowsLoader

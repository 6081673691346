import { ReactElement } from 'react'
import { useUser } from '../hooks/user.hooks'
import styled from '@emotion/styled'
import tw from 'twin.macro'

type RequestStatus = 'accepted' | 'denied' | 'queued' | 'played' | 'succeeded'

function StatusBadge({ status }: { status: RequestStatus }): ReactElement {
  const Accepted = styled.span`
    ${tw`text-green-500 bg-green-100 border-green-300`}
  `
  const Denied = styled.span`
    ${tw`text-rose-500 bg-rose-100 border-rose-300`}
  `
  const Queued = styled.span`
    ${tw`text-yellow-500 bg-yellow-100 border-yellow-300`}
  `
  if (status === 'accepted' || status === 'played' || status === 'succeeded')
    return <Accepted id="badge">Accepted</Accepted>
  else if (status === 'denied') return <Denied id="badge">{status}</Denied>
  else if (status === 'queued') return <Queued id="badge">Pending</Queued>
  else return <></>
}

export default StatusBadge

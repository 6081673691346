import { useAuth } from '@clerk/clerk-react'
import { useEffect, useCallback, useRef, useState } from 'react'

const noop = () => {
  // No Operation
}

type RETURN_TYPE = void
type BODY_TYPE = any
type HEADER_TYPE = Record<string, string>

export const useAbortableFetch = <
  ReturnType = RETURN_TYPE,
  BodyType = BODY_TYPE,
  HeaderType extends Record<string, string> = HEADER_TYPE
>({
  url,
  authed = false,
  type = 'GET',
  responseType = 'JSON',
  headers = {} as HeaderType,
  body = {} as BodyType,
  requestFailed = noop,
  requestStarted = noop,
  requestSuccessful = noop
}: {
  url: string
  authed?: boolean
  type: 'GET' | 'POST' | 'PUT' | 'DELETE'
  responseType?: 'TEXT' | 'JSON' | 'BLOB'
  headers?: HeaderType
  body?: BodyType
  requestStarted?: () => void
  requestSuccessful?: (arg: { data: ReturnType }) => void
  requestFailed?: (arg: { error: Error }) => void
}) => {
  const { getToken } = useAuth()
  const abortControllerRef = useRef<AbortController | null>(null)
  const [isLoading, setLoadingState] = useState(false)

  const triggerFn = useCallback(
    async (
      runtimeArg: {
        body?: BodyType
        headers?: HeaderType
        url?: string
      } = {}
    ) => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort()
      }

      const abortController = new AbortController()
      abortControllerRef.current = abortController

      requestStarted()

      try {
        if (authed) {
          setLoadingState(true)
          const token = await getToken()
          const response = await fetch(runtimeArg.url ? runtimeArg.url : url, {
            signal: abortController.signal,
            headers: {
              Authorization: `Bearer ${token}`,
              ...(['POST', 'PUT'].includes(type)
                ? {
                    'Content-Type': 'application/json'
                  }
                : {}),
              ...(runtimeArg.headers ? runtimeArg.headers : headers)
            },
            method: type,
            ...(['POST', 'PUT'].includes(type)
              ? {
                  body: JSON.stringify(runtimeArg.body ? runtimeArg.body : body)
                }
              : {})
          }).catch((err) => {
            if (err.name !== 'AbortError') {
              throw err
            }
          })

          if (response) {
            const data =
              responseType === 'JSON'
                ? await response.json()
                : responseType === 'BLOB'
                ? await response.blob()
                : await response.text()

            requestSuccessful({ data })
            setLoadingState(false)
          }
        } else {
          setLoadingState(true)
          const response = await fetch(runtimeArg.url ? runtimeArg.url : url, {
            signal: abortController.signal,
            headers: {
              ...(['POST', 'PUT'].includes(type)
                ? {
                    'Content-Type': 'application/json'
                  }
                : {}),
              ...(runtimeArg.headers ? runtimeArg.headers : headers)
            },
            method: type,
            ...(['POST', 'PUT'].includes(type)
              ? {
                  body: JSON.stringify(runtimeArg.body ? runtimeArg.body : body)
                }
              : {})
          }).catch((err) => {
            if (err.name !== 'AbortError') {
              throw err
            }
          })

          if (response) {
            const data =
              responseType === 'JSON'
                ? await response.json()
                : responseType === 'BLOB'
                ? await response.blob()
                : await response.text()

            // code omitted for brevity

            requestSuccessful({ data })
            setLoadingState(false)
          }
        }
      } catch (error: unknown) {
        if (error instanceof Error) requestFailed({ error })
        setLoadingState(false)
      }
    },
    // Update the function automatically if any of these properties change
    [
      url,
      authed,
      type,
      headers,
      body,
      requestStarted,
      requestSuccessful,
      requestFailed
    ]
  )

  return { isLoading, triggerFn } as const
}

import { Modal, Box, Button, TextField } from '@mui/material'
import QRCodeDialog from '../components/QRCode'
import React, { useState } from 'react'
import { useAtom } from 'jotai'
import { inviteCodeAtom } from '../store/code.store'
import { styled } from 'styled-components'
import tw from 'twin.macro'

const StyledModal = styled(Modal)`
  ${tw`flex items-center justify-center` as any}
`

const QRCodeModal = ({
  dialogOpen,
  handleDialogClose
}: {
  dialogOpen: boolean
  handleDialogClose: () => void
}) => {
  const [inviteCode, setInviteCode] = useAtom(inviteCodeAtom)

  const [codeForm, setCodeForm] = useState({
    code: ''
  })

  return (
    <StyledModal
      open={dialogOpen}
      onClose={handleDialogClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: 'absolute' as const,
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          p: 4,
          borderRadius: '1rem'
        }}
      >
        {inviteCode ? (
          <>
            <QRCodeDialog
              value={window.location.origin.concat('?code=', inviteCode || '')}
              size={256}
            ></QRCodeDialog>
            <div className="flex flex-row justify-center items-center text-2xl mt-4 mx-[-1rem] w-[60vw]">
              <h3>CODE: {inviteCode}</h3>
            </div>
          </>
        ) : (
          <div className="flex flex-col justify-center items-start w-full space-y-4 w-[60vw]">
            <h2 className="text-center pb-3">
              Enter an event invite code to instantly join!
            </h2>
            <TextField
              id="outlined-basic"
              label="Event Code"
              variant="outlined"
              fullWidth
              value={codeForm.code}
              inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                if (event.target.value.length <= 5)
                  setCodeForm({
                    code: event.target.value
                  })
              }}
            />
            <div className="py-3 w-[60vw] flex flex-row justify-center items-center">
              <Button
                size="small"
                variant="contained"
                onClick={() => setInviteCode(codeForm.code)}
                disabled={codeForm.code.length !== 5}
              >
                Go To Event
              </Button>
            </div>
          </div>
        )}
      </Box>
    </StyledModal>
  )
}

export default QRCodeModal

/* eslint-disable @typescript-eslint/no-explicit-any */

// Import necessary dependencies
import RoleGuard from '../../components/RoleGaurd'
import EventsListView from './EventsListView'
import UserEventView from './UserEventView'
import DJEventView from './DJEventView'
import { ReactElement, useEffect, useState, useRef } from 'react'
import { inviteCodeAtom } from '../../store/code.store'
import { useAtom } from 'jotai'
import { eventAtom } from '../../store/eventSession.store'
import { DJI } from '../../../shared/interfaces/DJ'
import { Event, Events } from '../../../shared/interfaces/Event'
import { useAbortableFetch } from '../../hooks/abortableFetch.hooks'
import { useUser } from '../../hooks/user.hooks'
import { djIdAtom } from '../../store/dj.store'
import { monthEnd, todayEnd, todayStart } from '../../utils/date.utils'

interface EventFilters {
  djList?: string
  dateRange?: {
    start: string
    end: string
  }
}

const defaultFilters: EventFilters = {
  djList: '',
  dateRange: {
    start: todayStart(),
    end: monthEnd()
  }
}
/**
 * Component for rendering the request page.
 * This component displays different content based on the user's role.
 * If the user is a DJ, it shows the DJRequestPage component, and if the user is a regular user, it shows the UserRequestPage component.
 *
 * @returns {ReactElement} - JSX element representing the request page.
 */
function EventsPage(): ReactElement {
  const { user } = useUser()
  const [inviteCode, setInviteCode] = useAtom(inviteCodeAtom)
  const [event, setEvent] = useAtom(eventAtom)
  const [events, setEvents] = useState<any[]>([])
  const [djEvents, setDJEvents] = useState<any[]>([])
  const [djID, setDjID] = useAtom(djIdAtom)
  const [filters, setFilters] = useState<EventFilters>(defaultFilters)
  const [reload, setReload] = useState<boolean>(false)

  const hasFilter = (prop: any, queryParam: string) => {
    return (prop as string) ? queryParam + prop : ''
  }

  const { triggerFn: loadDJId } = useAbortableFetch<DJI>({
    type: 'GET',
    url: '/api/dj/me',
    authed: true,
    requestFailed({ error }) {
      console.error(error)
    },
    requestSuccessful({ data }) {
      setDjID(data.id as string)
    }
  })

  const { isLoading: userLoading, triggerFn: listAllEvents } =
    useAbortableFetch<Events>({
      type: 'GET',
      url: `/api/event/list?${hasFilter(
        filters.dateRange?.start,
        'from='
      )}${hasFilter(filters.dateRange?.end, '&to=')}`,
      authed: true,
      requestFailed({ error }) {
        console.error(error)
      },
      requestSuccessful({ data }) {
        setEvents(data.events)
      }
    })

  const { isLoading: djLoading, triggerFn: listEventsByDJ } =
    useAbortableFetch<Events>({
      type: 'GET',
      url: `/api/event/list?djs=${djID}`,
      authed: true,
      requestFailed({ error }) {
        console.error(error)
      },
      requestSuccessful({ data }) {
        setDJEvents(data.events)
      }
    })

  useEffect(() => {
    listAllEvents()
    if (!!user && user?.role === 'dj') {
      if (!djID) {
        loadDJId().then(() => {
          listEventsByDJ()
        })
      }
      listEventsByDJ()
    }
  }, [inviteCode, djID, user, event])

  const delayUpdate = () => {
    setReload(true)
    setTimeout(() => {
      listEventsByDJ()
      setReload(false)
    }, 1000)
  }

  const setSelectedEvent = (code: string) => {
    setInviteCode(code)
  }
  return (
    // If no inviteCode is active, display a list view of events for DJs and Users
    <>
      {!inviteCode ? (
        <>
          {/* RoleGuard component for DJs */}
          <RoleGuard allow={['dj']}>
            <EventsListView
              selectEvent={setSelectedEvent}
              role={'dj'}
              events={djEvents}
              isLoading={djLoading || reload}
              onCreated={delayUpdate}
            />
          </RoleGuard>

          {/* RoleGuard component for regular users */}
          <RoleGuard allow={['user', 'dj']}>
            <EventsListView
              selectEvent={setSelectedEvent}
              role={'user'}
              events={events}
              isLoading={userLoading}
            />
          </RoleGuard>
        </>
      ) : (
        // If inviteCode is active, display either the DJ or User event view
        <>
          {/* RoleGuard component for DJs */}
          <div className="w-full mx-auto container flex flex-col flex-grow">
            {event?.dj.id === djID ? (
              <DJEventView />
            ) : (
              <UserEventView code={inviteCode} />
            )}
          </div>
        </>
      )}
    </>
  )
}

// Export the RequestPage component as the default export
export default EventsPage

import { ReactElement } from 'react'
import Icons from './../icons'

import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined'

type CardTypes =
  | `amex`
  | `diners`
  | `discover`
  | `jcb`
  | `mastercard`
  | `unionpay`
  | `visa`
  | `unknown`

function CardIcon({ name }: { name?: CardTypes | string }): ReactElement {
  const renderIcon = () => {
    switch (name) {
      case 'amex':
        return <Icons.AmEx />
        break
      case 'diners':
        return <Icons.Diners />
        break
      case 'discover':
        return <Icons.Discover />
        break
      case 'jcb':
        return <Icons.JCB />
        break
      case 'mastercard':
        return <Icons.MasterCard />
        break
      case 'unionpay':
        return <Icons.UnionPay />
        break
      case 'visa':
        return <Icons.Visa />
        break
      default:
        return <Icons.Stripe />
        break
    }
  }
  return <>{renderIcon()}</>
}

export default CardIcon
